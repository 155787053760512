import { MenuOutlined } from "@ant-design/icons";
import { Input, Layout, Menu, Select, Space } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from "../../context/AppContext";
import './BaseLayout.css';
import { MenuItem } from "../../types/MenuItem.def";
import { LogoutModal } from "../Logout/LogoutModal";

const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { Option } = Select;


const BaseLayout: React.FC = () => {

  const createMenuItem = (key: string, label: string, children?: MenuItem[]) : MenuItem => ({
    key: key,
    label: !children ? <Link to={key}>{label}</Link> : label , ...(children && { children }),
  });

  const _menuData = {
      "Properties": createMenuItem('/property-search', 'Properties'),
      "Manage": createMenuItem('/manage', 'Manage', [
                  createMenuItem('/manage/account', 'Account'),
                  createMenuItem('/manage/value-estimator', 'Value Estimator'),
                  {
                    key: 'logout',
                    label: <div onClick={() => setIsLogoutModalOpen(true)}>Log Out</div>
                  }
      ]),
      "Loans": createMenuItem('/loans', 'Loans')
  };

  const menuKeys : string[] = Object.keys(_menuData).map(key => _menuData[key].key);

  const getMenuItems: (tabNames: string[]) => MenuItem[] = (tabNames) => {
      const result: MenuItem[] = [];
      for (const tabName of tabNames) {
          if (_menuData[tabName]) {
              result.push(_menuData[tabName]);
          }
      }
      return result;
  }

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);

  const { isLoggedIn, allowedMenuItems, setAllowedMenuItems } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [searchType, setSearchType] = useState('address');
  const [loading, setLoading] = useState(false);
  const storage = localStorage;
  
  useEffect(() => {
    for(let i = 0; i < menuKeys.length; i++) {
      if(menuKeys[i].includes(location.pathname)) {
        setSelectedKey(menuKeys[i]);
        return;
      }
    }
    setSelectedKey(null);
  }, [location]);

  useEffect(() => {
    if ((allowedMenuItems == null || allowedMenuItems.length == 0) && isLoggedIn) {
      const cachedAllowedMenuItems = JSON.parse(storage.getItem('menuItems'));
      setMenuItems(cachedAllowedMenuItems);
      setAllowedMenuItems(cachedAllowedMenuItems);
    } else if (isLoggedIn){
      setMenuItems(getMenuItems(allowedMenuItems));
    } else {
      setMenuItems(getMenuItems(["Properties"]));
    }
  }, [allowedMenuItems])

  const prefix = (
    <Space>
      <Select defaultValue="address" className="search-type-select" value={searchType} onChange={(value) => setSearchType(value)}>
        <Option value="address">Address</Option>
        <Option value="listNumber">List #</Option>
        <Option value="owner">Owner</Option>
        <Option value="taxId">Tax ID</Option>
        <Option value="text">Text</Option>
      </Select>
    </Space>
  );

  

  const onSearch: SearchProps['onSearch'] = async (value) => {
    //todo: navigate to property search and pass in the search type somehow
    if(value.length > 3) {
      let url = "/property-search?key=" + searchType + "&value=" + value;
      navigate(url);
    }
  }

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', padding: '0 25px', background: '#edf6ff', borderBottom: '1px solid #DFE8F0 '}} >
          <div style={{marginRight: 15 }}>
            <Link to="/home"><img src="../../../trueavm.svg" style={iconStyle} alt="trueavm logo" /></Link>
          </div>
          <div style={{minWidth: "50%", maxWidth: "66%"}}>
            <Search addonBefore={prefix} placeholder="Search..." allowClear onClear={() => (window.location.pathname === '/property-search') ? navigate("/property-search") : null } onSearch={onSearch} loading={loading} enterButton style={{ paddingTop: 15, marginRight: 5, width: 'minmax(180, 100%)'}}/>
          </div>
          <Menu
            overflowedIndicator={<MenuOutlined style={{fontSize: 20}} />}
            mode="horizontal"
            selectedKeys={[selectedKey]}
            style={{ flex: 1, minWidth: 0, background: "#edf6ff" }}
            items={menuItems}   
          />
        </Header>
        <Content style={{ padding: '0 10px' }}>
          <div
            className="content"
            style={{
              marginTop: 2,
              paddingBottom: 10
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: '2vw' }}>
          TrueAVM ©{new Date().getFullYear()} Created by TrueAVM, LLC
        </Footer>
      </Layout>
      <LogoutModal show={isLogoutModalOpen} close={() => setIsLogoutModalOpen(false)}/>
    </>
  );
};

const iconStyle = {height: '40px', width: "auto", marginTop: 10, marginLeft: '-15px'}

export default BaseLayout;