import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Row, Skeleton } from 'antd';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/shared-functions';
import { WorkspaceParcel } from '../../../types/WorkspaceParcel.def';
import WorkspaceParcelDetail from '../WorkspaceParcelDetail/WorkspaceParcelDetail';

interface WorkspaceParcelListProps {
    results: WorkspaceParcel[];
    type: 'title' | 'sale';
    loading: boolean;
}

const WorkspaceParcelList: FC<WorkspaceParcelListProps> = ({results, type, loading}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedParcel, setSelectedParcel] = useState<WorkspaceParcel | null>(null);

    const handleOpenModal = (parcel: WorkspaceParcel) => {
        setSelectedParcel(parcel);
        setIsModalOpen(true);
    };

    const IS_MOBILE = window.innerWidth < 768;

    const truncateString = (str: string, maxLength: number = 18) => {
        if (str === null || str === undefined) return " - ";
        if (str.length <= maxLength) return str;
        return str.substring(0, maxLength) + '...';
    };

    const description = (item: WorkspaceParcel) => {
        if(type === 'title') {
            return (
                <Row gutter={[16, 8]}>
                                    <Col span={6}>
                                        <div>Date/Time: <b style={{color: '#444'}}>{formatDate(item.otherPartyDateTime, false) || " - "}</b></div>
                                    </Col>
                                    <Col span={6}>
                                        <div>Entry Type: <b style={{color: '#444'}}>{truncateString(item.entryType) || " - "}</b></div>
                                    </Col>
                                    <Col span={6}>
                                        <div>First Party: <b style={{color: '#444'}}>{truncateString(item.firstParty) || " - "}</b></div>
                                    </Col>
                                    <Col span={6}>
                                        <div>Second Party: <b style={{color: '#444'}}>{truncateString(item.secondParty) || " - "}</b></div>
                                    </Col>
                                </Row>
            )
        } else {
            return (
                <Row gutter={[16, 8]}>
                    <Col span={6}>
                        {/* <div>List Number: {item.listNumber}</div> */}
                        {item.listNumber ? " List Number: " : ""}
                        <Link to={`https://www.utahrealestate.com/${item.listNumber}`}>
                            <Button type="link">{item.listNumber}</Button>
                        </Link>
                    </Col>
                    <Col span={6}>
                        <div>List Date: <b style={{color: '#444'}}>{formatDate(item.listDate, false) || " - "}</b></div>
                    </Col>
                    <Col span={6}>
                        <div>List Status: <b style={{color: '#444'}}>{item.listStatus || " - "}</b></div>
                    </Col>
                    <Col span={6}>
                        <div>Listing Changed: <b style={{color: '#444'}}>{formatDate(item.listingChangeDateTime, false) || " - "}</b></div>
                    </Col>
                </Row>
            )
        }
    }

    return (
        <>
            <List
                dataSource={loading ? Array(10).fill({}) : results}
                renderItem={(item) => (
                    <List.Item
                        style={{display: 'flex', width: '100%'}}
                        actions={loading ? [] : [
                            <InfoCircleOutlined 
                                onClick={() => handleOpenModal(item)}
                                style={{ fontSize: '16px', cursor: 'pointer' }}
                            />
                        ]}
                    >
                        {loading ? (
                            <Skeleton active paragraph={{ rows: 2 }} />
                        ) : (
                            <List.Item.Meta
                                title={
                                    <Link to={`/property-search/view/${item.propertyId}?tab=${type === 'title' ? 'Title-History' : 'Sale-History'}`}>
                                        <Button style={{fontWeight: 'bold', padding: 0, fontSize: '16px'}} type="link" >{IS_MOBILE ? item.address : item.fullAddress}</Button>
                                    </Link>
                                }
                                description={description(item)}
                            />
                        )}
                    </List.Item>
                )}
            />

            <Modal
                title="Details"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                {selectedParcel && <WorkspaceParcelDetail parcel={selectedParcel} />}
            </Modal>
        </>
    );
};

export default WorkspaceParcelList;
