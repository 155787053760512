import { Card, Flex, Image, Statistic, Tag, Typography } from 'antd';
import React from 'react';
import { PropertySummaryProps } from '../../types/PropertySummary.def';

import { Link } from 'react-router-dom';
import './PropertyCard.css';

const { Text } = Typography;

const PropertyCard: React.FC<PropertySummaryProps> = (props) => {

    const { property } = props;

    const fullAddress: string = `${property?.address}, ${property?.city}, ${property?.stateAbbrev}`;

    const activeString = property?.isListed ? "Active" : "Inactive";

    return (
        <div >
            <Link to={`/property-search/view/${property.id}`}>
                <Card
                    className='property-card'
                    hoverable
                    style={{ width: '20vw', maxWidth: 300, minWidth: 250, cursor: 'pointer' }}
                    cover={
                        <Image
                            draggable={false}
                            preview={false}
                            style={{ maxHeight: 225, objectFit: 'cover', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                            src={property?.primaryPhotoUrl}
                            fallback="../../../img/missing-img.png"
                        />
                    }
                >
                    <Text type='secondary' style={{ fontSize: 13, overflowX: 'hidden', textOverflow: 'ellipsis' }}>{fullAddress.length > 24 ? fullAddress.substring(0, 21) + "..." : fullAddress}</Text>
                    <Flex justify='space-between'>
                        <Statistic
                            // value={formatNumber(property?.valuation) || "N/A"}
                            value={property?.valuation || "N/A"}
                            precision={0}
                            prefix={!!property?.valuation ? "$" : ""}
                            suffix={<div style={{ fontSize: 10 }} className='light-text'>est.</div>}
                        />
                        <Tag color={property?.isListed ? "success" : "warning"} style={{ alignItems: 'center', height: 25, marginTop: '2.5%' }}>
                            {activeString}
                        </Tag>
                    </Flex>

                </Card>
            </Link>
        </div>
    );
}

export default PropertyCard;