import { MarketConditionsInfo } from "../../../types/MarketConditionsInfo.def";

export const Forcast = (marketConditionInfo: MarketConditionsInfo) => {
  return [
    {
      title: 'Next Year',
      content: marketConditionInfo?.forecastedAnnualValueChangeNextYear ? `${((marketConditionInfo?.forecastedAnnualValueChangeNextYear * 100).toFixed(2))}%` : '-'
    }
  ];
}

export const Statistics = (marketConditionInfo: MarketConditionsInfo) => {
  return [
    {
      title: 'Subdivision', 
      content: marketConditionInfo?.subdivisionName || '-'
    },
    {
      title: 'Neighborhood',
      content: marketConditionInfo?.neighborhoodName || '-'
    },
    {
      title: 'Community',
      content: marketConditionInfo?.communityName || '-'
    },
  ];
}

export const HomePriceIndex = (marketConditionInfo: MarketConditionsInfo) => {
  return [
    {
      title: 'MSA',
      content: marketConditionInfo?.msaName || '-'
    }
  ];
}