import { Property } from "./Property.def";

export enum ValuationType {
    Active = 'valuationByActiveListings',
    BestComps = "valuationByBestComps",
    Features = 'valuationByFeatures',
    SoldComps = 'valuationBySoldComps',
    ValuationMethods = "valuationMethods"
}

export interface ValuationListData {
    header?: string[], 
    rows: {
            label: string, 
            info?: string,
            values: {
                value : (string | number),
                suffix?: string
            }[],
    }[],
    columnCount?: number
}

export interface PropertyValuationProps {
    property: Property;
    printView?: boolean;
}

export interface ValuationMethodsDataType {
    currentValueByFeatures: number,
    currentValueByAuditor: number,
    currentValueBySales: number,
    currentValueBySoldComps: number,
    currentValueByBestListings: number,
    currentValue: number,
    confidence: number,
    parcelAcres: number,
    parcelSqFt: number,
    listingAcres: number,
    listingSqFt: number,
    saleAcres: number | null,
    saleSqFt: number | null
}

export interface ValuationByFeaturesDataType {
    currentValueByJoinedFeatures: number,
    currentLandValue: number,
    currentImprovementsValue: number,
    currentValueByFeatures: number
}

export interface ValuationBySoldCompsDataType {
    valuationSoldsTogether: number,
    valuationOfLandBySolds: number,
    valuationOfImprovementsBySolds: number,
    valuationBySolds: number
}

export interface ValuationByActiveListingsDataType {
    valuationActivesTogether: number,
    valuationActivesLand: number
    valuationActivesImprovements: number,
    valuationActives: number
}

export interface BestCompsParams {
    maxComps: number,
    milesRange: number,
    maxMonthsBack: number,
    sqFtPercentRange: number,
    acresPercentRange: number,
    yearBuiltRange: number,
    limit: number
}

export interface AdjustCharacteristicsParams {
    propertySubTypeId: number,
    bedrooms: number,
    bathrooms: number,
    yearBuilt: number,
    acres: number,
    mainLevelSqFt: number,
    upperLevelsSqFt: number,
    aboveGradeSqFt: number,
    finishedBasementSqFt: number,
    unfinishedBasementSqFt: number,
    finishedLivingSqFt: number
}

export interface Sandbox {
    valuationByFeatures: number,
    valuationBySoldComps: number,
    valuationByActiveListings: number,
    valuation: number,
    propertySubTypeId: number,
    acres: number,
    mainLevelSqFt: number,
    upperLevelsSqFt: number,
    aboveGradeSqFt: number,
    finishedBasementSqFt: number,
    unfinishedBasementSqFt: number,
    finishedLivingSqFt: number,
    bedrooms: number,
    bathrooms: number,
    yearBuilt: number,
}

export function DEFAULT_BEST_COMPS_PARAMS(): BestCompsParams {
    return {
        maxComps: 5,
        milesRange: .5,
        maxMonthsBack: 12,
        sqFtPercentRange: 75,
        acresPercentRange: 50,
        yearBuiltRange: 20,
        limit: 50
    }
}
