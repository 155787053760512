import { toolTipTitle } from "../PropertyUtil"
import { Property } from "../../../types/Property.def"
import { Sandbox, ValuationType } from "../../../types/PropertyValuationsTypes.def"
import { numberWithCommas } from "../../../shared/shared-functions"

export const propertyValuationsData = (property: Property) => {
    return [
        {
            title: "Features",
            content: property?.valuationFeatures ? `$${numberWithCommas(property?.valuationFeatures)}` : "-",
            info: "Valuation based on this property's features.",
            type: ValuationType.Features
        },
        {
            title: "Adj. Auditor",
            content: property?.valuationAdjAuditor ?  `$${numberWithCommas(property?.valuationAdjAuditor)}` : "-",
            info: "Based on this property's auditor valuation."
        },
        {
            title: "Adj. Prior Sale",
            content: property?.valuationAdjPriorSale ?  `$${numberWithCommas(property?.valuationAdjPriorSale)}` : "-",
            info: "Based on this property's prior sales."
        },
        {
            title: "Sold Comps",
            content: property?.valuationSolds ?  `$${numberWithCommas(property?.valuationSolds)}` : "-",
            info: "Based on data from the sale of similar properties.",
            type: ValuationType.SoldComps
        },
        {
            title: "Active Listings",
            content: property?.valuationActives ?  `$${numberWithCommas(property?.valuationActives)}` : "-",
            info: "Based on comparable properties currently for sale.",
            type: ValuationType.Active
        },
        {
            title: 'Estimated Value',
            content: property?.valuation ?  `$${numberWithCommas(property?.valuation)}` : "-",
            type: ValuationType.ValuationMethods
        },
        {
            title: "Confidence",
            content: property?.confidenceDescription ? <div>{toolTipTitle({title: property?.confidenceDescription, info: `${(property.confidence*100).toFixed(2)}%`})}</div> : "-",
            info: "A measure of data availability and the similarity of valuations by different methods."
        },
    ]
}

export const bestCompsData = (property : Property) => {
    return [
        {
            title: 'Best Comps', // Whats going on here? Best Comps name, but valuationSoldsSimple is the field?
            content: property?.valuationSoldsSimple ?  `$${numberWithCommas(property?.valuationSoldsSimple)}` : "-",
            type: ValuationType.BestComps
        },
    ] 
}

export const taxableValueData = (property : Property) => {
    return [
        {
            title: 'Land',
            content: property?.auditorValuationOfLandGov ? `$${numberWithCommas(property?.auditorValuationOfLandGov)}` : "-"
        },
        {
            title: 'Building',
            content: property?.auditorValuationOfImprovementsGov ? `$${numberWithCommas(property?.auditorValuationOfImprovementsGov)}` : "-"
        },
        {
            title: 'Total',
            content: property?.valuationAdjAuditor ? `$${numberWithCommas(property?.auditorValuation)}` : "-"
        }
    ] 
}

export const saleHistoryData = (property: Property) => {
    return property?.propertySales?.map((sale) => {
        return (
            {
                title: new Date(sale.saleDate).toLocaleDateString(),
                content: sale?.saleAmount ? `$${numberWithCommas(sale?.saleAmount)}` : "-"
            }
        )
    });
}

export const adjustCharacteristicsData = (adjustCharacteristicsSandbox: Sandbox) => {
    return [
        {
            title: 'Valuation By Features',
            content: adjustCharacteristicsSandbox.valuationByFeatures ? `$${numberWithCommas(adjustCharacteristicsSandbox.valuationByFeatures)}` : "-"
        },
        {
            title: 'Valuation By Sold Comps',
            content: adjustCharacteristicsSandbox.valuationBySoldComps ? `$${numberWithCommas(adjustCharacteristicsSandbox.valuationBySoldComps)}` : "-"
        },
        {
            title: 'Valuation By Active Listings',
            content: adjustCharacteristicsSandbox.valuationByActiveListings ? `$${numberWithCommas(adjustCharacteristicsSandbox.valuationByActiveListings)}` : "-"  
        },
        {
            title: 'Valuation',
            content: adjustCharacteristicsSandbox.valuation ? `$${numberWithCommas(adjustCharacteristicsSandbox.valuation)}` : "-"
        }
    ]
}
