import { DollarOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { PropertySummary } from '../../types/PropertySummary.def';
import PropertySlider from '../PropertySlider/PropertySlider';


import { TinyColor } from '@ctrl/tinycolor';
import { Link } from 'react-router-dom';
import { useHttpService } from '../../hooks/UseHttpService';
import './Home.css';

export default function Home() {

    const http = useHttpService();

    const storage = localStorage;

    const [recentProperties, setRecentProperties] = useState<PropertySummary[]>([]);

    const userFirstName = storage.getItem('firstName');

    useEffect(() => {
        let params: any = {
            limit: 12
        }
        http.get("/viewedProperties?limit=12")
            .then(res => {
                let data = res.data.properties;
                setRecentProperties(data);
            })
            .catch(err => {
                console.error(err);
            })
    }, [])

    

    const colors1 = ['#6253E1', '#04BEFE'];
    const colors2 = ['#fc6076', '#ff9a44', '#ef9d43', '#e75516'];
    const colors3 = ['#40e495', '#30dd8a', '#2bb673'];

    const getHoverColors = (colors: string[]) =>
        colors.map((color) => new TinyColor(color).lighten(5).toString());

    const getActiveColors = (colors: string[]) =>
        colors.map((color) => new TinyColor(color).darken(5).toString());

    return (
        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            <div className='background'>
                <Typography.Title level={2} style={{ margin: 0, color: 'white' }}>
                    Welcome, {userFirstName}
                </Typography.Title>
                <Typography.Title level={4} style={{ color: 'white', margin: 0 }}>
                    What would you like to do today?
                </Typography.Title>
                <br></br>
                <Space direction='horizontal'>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    colorPrimary: `linear-gradient(90deg,  ${colors2.join(', ')})`,
                                    colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(colors2).join(', ')})`,
                                    colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(colors2).join(', ')})`,
                                    lineWidth: 0,
                                },
                            },
                        }}
                    >
                        <Link to='/property-search'>                        
                            <Button
                                className='hoverCard'
                                type="primary"
                                icon={<SearchOutlined />}
                            >
                                Property Search
                            </Button>
                        </Link>
                    </ConfigProvider>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    colorPrimary: `linear-gradient(90deg,  ${colors3.join(', ')})`,
                                    colorPrimaryHover: `linear-gradient(90deg, ${getHoverColors(colors3).join(', ')})`,
                                    colorPrimaryActive: `linear-gradient(90deg, ${getActiveColors(colors3).join(', ')})`,
                                    lineWidth: 0,
                                },
                            },
                        }}
                    >
                        <Link to='/manage/value-estimator'>
                            <Button
                                style={{width: '150px'}}
                                className='hoverCard'
                                type="primary"
                                icon={<DollarOutlined />}
                            >
                                Value Estimator
                            </Button>
                        </Link>
                    </ConfigProvider>
                </Space>
            </div>
            <PropertySlider 
                emptyMessage="Let's get started" 
                mainMessage='Pick Up Where You Left Off' 
                linkMessage='View All Recent Properties'
                linkURL={`/property-search`}
                queryParams={{
                    recent: true
                }}
                properties={recentProperties}
            />
        </Space>
    )
}