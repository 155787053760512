import { Button, Drawer, Flex, List, Modal, Spin, Table, Typography, Input, Collapse, Select } from 'antd';
import React, { useState } from 'react';
import { useHttpService } from '../../../hooks/UseHttpService';
import { ageInYearsFromYear, formatDate, toast_error } from '../../../shared/shared-functions';
import { Property } from "../../../types/Property.def";
import { AdjustCharacteristicsParams, BestCompsParams, DEFAULT_BEST_COMPS_PARAMS, PropertyValuationProps, Sandbox, ValuationByActiveListingsDataType, ValuationByFeaturesDataType, ValuationBySoldCompsDataType, ValuationListData, ValuationMethodsDataType, ValuationType } from "../../../types/PropertyValuationsTypes.def";
// import './PropertyValuations.css';
import { adjustCharacteristicsData, bestCompsData, propertyValuationsData, saleHistoryData, taxableValueData } from './PropertyValuationsData';
import { ValuationByActiveListingsInfo, ValuationByFeaturesInfo, ValuationBySoldCompsInfo, ValuationMethodsInfo, ValuationMethodsSource } from './ValuationDrillDownInfo';
import ValuationList from "./ValuationList";
import { useNavigate } from "react-router-dom";
import { renderListItem } from "../PropertyUtil";
import { RightOutlined } from "@ant-design/icons";
 
const PropertyValuations: React.FC<PropertyValuationProps> = ({property, printView = false}) => {

    const http = useHttpService();
    const navigate = useNavigate();

    const data = propertyValuationsData(property);
    const saleHistory = saleHistoryData(property);
    const bestCompsInfo= bestCompsData(property);
    const taxableValue= taxableValueData(property);

    const [open, setOpen] = useState<boolean>(false);
    const [drawerLoading, setDrawerLoading] = useState<boolean>(false);
    const [drillDownInfo, setDrillDownInfo] = useState<ValuationListData>(null);
    const [drillDownExtraInfo, setDrillDownExtraInfo] = useState<ValuationListData>(null);

    const [valuationMethodsData, setValuationMethodsData] = useState<ValuationListData>(null);
    const [valuationMethodsSource, setValuationMethodsSource] = useState<ValuationListData>(null);
    const [valuationByFeaturesData, setValuationByFeaturesData] = useState<ValuationListData>(null);
    const [valuationBySoldCompsData, setValuationBySoldCompsData] = useState<ValuationListData>(null);
    const [valuationByActiveListingsData, setValuationByActiveListingsData] = useState<ValuationListData>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isAdjustCharacteristicsModalOpen, setIsAdjustCharacteristicsModalOpen] = useState<boolean>(false);
    const [bestCompsList, setBestCompsList] = useState<Property[]>([]);
    const [adjustCharacteristicsSandbox, setAdjustCharacteristicsSandbox] = useState<Sandbox>(null);
    const [bestCompsLoading, setBestCompsLoading] = useState<boolean>(false);
    const [adjustCharacteristicsLoading, setAdjustCharacteristicsLoading] = useState<boolean>(false);
    const [params, setParams] = useState<BestCompsParams>(DEFAULT_BEST_COMPS_PARAMS());
    const [adjustCharacteristicsParams, setAdjustCharacteristicsParams] = useState<AdjustCharacteristicsParams>(null);

    const modalStyle = { maxWidth: '1000px', minWidth: '350px' };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const openModal = async () => {
        setIsModalOpen(true);
        await fetchBestCompsData();
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const openAdjustCharacteristicsModal = () => {
        setIsAdjustCharacteristicsModalOpen(true);
    }

    const handleAdjustCharacteristicsModalClose = () => {
        setIsAdjustCharacteristicsModalOpen(false);
    }

    const fetchBestCompsData = () => {
        setBestCompsLoading(true);
        let url = `/property/valuationByBestComps?maxComps=${params.maxComps}`
        url += `&milesRange=${params.milesRange}&maxMonthsBack=${params.maxMonthsBack}`
        url += `&sqFtPercentRange=${params.sqFtPercentRange}&acresPercentRange=${params.acresPercentRange}`
        url += `&yearBuiltRange=${params.yearBuiltRange}&limit=${params.limit}&propertyId=${property.id}`;
        
        http.get(url).then(res => {
            setBestCompsList(res.data.comps);
        }).catch(error => {
            console.error('Error fetching comps:', error);
            setBestCompsList([]);
        }).finally(() => {
            setBestCompsLoading(false);
        })
    }

    const fetchAdjustCharacteristicsData = () => {
        setAdjustCharacteristicsLoading(true);
        let url = `/property/sandbox?`
        url += `propertyId=${property.id}`

        url += adjustCharacteristicsParams?.propertySubTypeId 
            ? `&propertySubTypeId=${adjustCharacteristicsParams.propertySubTypeId}` : ''
        url += adjustCharacteristicsParams?.bedrooms 
            ? `&bedrooms=${adjustCharacteristicsParams.bedrooms}` : ''
        url += adjustCharacteristicsParams?.bathrooms 
            ? `&bathrooms=${adjustCharacteristicsParams.bathrooms}` : ''
        url += adjustCharacteristicsParams?.yearBuilt 
            ? `&yearBuilt=${adjustCharacteristicsParams.yearBuilt}` : ''
        url += adjustCharacteristicsParams?.acres 
            ? `&acres=${adjustCharacteristicsParams.acres}` : ''
        url += adjustCharacteristicsParams?.mainLevelSqFt 
            ? `&mainLevelSqFt=${adjustCharacteristicsParams.mainLevelSqFt}` : ''
        url += adjustCharacteristicsParams?.upperLevelsSqFt 
            ? `&upperLevelsSqFt=${adjustCharacteristicsParams.upperLevelsSqFt}` : ''
        url += adjustCharacteristicsParams?.aboveGradeSqFt 
            ? `&aboveGradeSqFt=${adjustCharacteristicsParams.aboveGradeSqFt}` : ''
        url += adjustCharacteristicsParams?.finishedBasementSqFt 
            ? `&finishedBasementSqFt=${adjustCharacteristicsParams.finishedBasementSqFt}` : ''
        url += adjustCharacteristicsParams?.unfinishedBasementSqFt 
            ? `&unfinishedBasementSqFt=${adjustCharacteristicsParams.unfinishedBasementSqFt}` : ''
        url += adjustCharacteristicsParams?.finishedLivingSqFt 
            ? `&finishedLivingSqFt=${adjustCharacteristicsParams.finishedLivingSqFt}` : ''
        
        http.get(url).then(res => {
            const data = res.data;
            setAdjustCharacteristicsSandbox(data);
            if (!adjustCharacteristicsParams) {
                setAdjustCharacteristicsParams({
                    propertySubTypeId: data.propertySubTypeId,
                    bedrooms: data.bedrooms,
                    bathrooms: data.bathrooms,
                    yearBuilt: data.yearBuilt,
                    acres: data.acres,
                    mainLevelSqFt: data.mainLevelSqFt,
                    upperLevelsSqFt: data.upperLevelsSqFt,
                    aboveGradeSqFt: data.aboveGradeSqFt,
                    finishedBasementSqFt: data.finishedBasementSqFt,
                    unfinishedBasementSqFt: data.unfinishedBasementSqFt,
                    finishedLivingSqFt: data.finishedLivingSqFt,
                });
            }
        }).catch(error => {
            console.error('Error fetching comps:', error);
            setAdjustCharacteristicsSandbox(null);
        }).finally(() => {
            setAdjustCharacteristicsLoading(false);
        })
    }

    const handleAddressClick = (id: number) => {
        handleModalClose();
        navigate(`/property-search/view/${id}`);
    }

    const fetchValuationData = async <T,>(valType: ValuationType) : Promise<T | null> => {
        return await http.get(`/property/${valType}?propertyId=${property.id}`).then(res =>{
            if (res.success)
                return res.data as T;
            else
                toast_error(res.message);
            return null;
        })
    };

    const loadValuationData = async (valType : ValuationType) => {
        if (valType == ValuationType.BestComps) {
            openModal();
            return;
        }
        showDrawer();
        setDrawerLoading(true);
        setDrillDownExtraInfo(null);
        switch (valType) {
            case ValuationType.ValuationMethods:
                if (!!!valuationMethodsData) {
                    const rawData = await fetchValuationData<ValuationMethodsDataType>(valType);
                    const data = ValuationMethodsInfo(rawData);
                    const data2 = ValuationMethodsSource(rawData);
                    setValuationMethodsData(data);
                    setDrillDownInfo(data);
                    setValuationMethodsSource(data2);
                    setDrillDownExtraInfo(data2)
                }
                else
                    setDrillDownInfo(valuationMethodsData);
                break;
            case ValuationType.Features:
                if (!!!valuationByFeaturesData) {
                    const data = ValuationByFeaturesInfo(await fetchValuationData<ValuationByFeaturesDataType>(valType));
                    setValuationByFeaturesData(data);
                    setDrillDownInfo(data);
                }
                else
                    setDrillDownInfo(valuationByFeaturesData);
                break;
            case ValuationType.SoldComps:
                if (!!!valuationBySoldCompsData) {
                    const data = ValuationBySoldCompsInfo(await fetchValuationData<ValuationBySoldCompsDataType>(valType));
                    setValuationBySoldCompsData(data);
                    setDrillDownInfo(data);
                }
                else
                    setDrillDownInfo(valuationBySoldCompsData);
                break;
            case ValuationType.Active:
                if (!!!valuationByActiveListingsData) {
                    const data = ValuationByActiveListingsInfo(await fetchValuationData<ValuationByActiveListingsDataType>(valType));
                    setValuationByActiveListingsData(data);
                    setDrillDownInfo(data);
                }
                else
                    setDrillDownInfo(valuationBySoldCompsData);
                break;
            // case ValuationType.BestComps:
            //     break;
        }
        setDrawerLoading(false);
    }

    const handleAdjustCharacteristicsClick = () => {
        fetchAdjustCharacteristicsData();
        openAdjustCharacteristicsModal();
    }

    const moreButtonValue = (item) => { // TODO: get rid of, or only use statistic Or move logic into separate funciton maybe using separate number comas?
        if(item.type && item.content != null && item.content != '-')
            return (
                <Button onClick={() => loadValuationData(item.type)} size='small' type='link' style={{justifyContent: "left", paddingLeft:"0px", width: 'fit-content'}} >
                    {item.content}<RightOutlined />
                </Button>
            )
        return item.content;
    }

    return (
        <>
            <div>
                <List
                    header = {<Typography.Text style={{fontWeight: 'bold'}}>Component Valuations</Typography.Text>}
                    dataSource={data}
                    renderItem={(item) => renderListItem(item, moreButtonValue)}
                >
                    <List.Item>
                        <div style={{ textAlign: 'center' }}>
                            { printView ? <></> : <Button type='link' size='small' onClick={handleAdjustCharacteristicsClick}>Adjust Characteristics</Button> }
                        </div>
                    </List.Item>
                </List>
                <List
                    header = {<Typography.Text style={{fontWeight: 'bold'}}>Custom Valuation</Typography.Text>}
                    dataSource={bestCompsInfo}
                    renderItem={(item) =>renderListItem(item, moreButtonValue)}
                />
                <List
                    header = {<Typography.Text style={{fontWeight: 'bold'}}>Taxable Value (From Assessor)</Typography.Text>}
                    dataSource={taxableValue}
                    renderItem={(item) => renderListItem(item)}
                />
                <List
                    header = {<Typography.Text style={{fontWeight: 'bold'}}>Sale History</Typography.Text>}
                    dataSource={saleHistory}
                    renderItem={(item) => renderListItem(item)}
                />
            </div>
            <Drawer width={400} title="Valuation Details" maskClosable onClose={onClose} open={open} loading={drawerLoading} >
                <ValuationList data={drillDownInfo} />
                <br/>
                <ValuationList data={drillDownExtraInfo} />
            </Drawer>
            <Modal
                title={
                    <>
                        <Typography.Text style={{fontSize: 18}}>Best Comps</Typography.Text>
                        <Collapse defaultActiveKey={[]} style={{marginBottom: 16, marginTop: 16}}>
                            <Collapse.Panel header="Parameters" key="1">
                                <Flex wrap="wrap" gap="small" style={{ marginBottom: 16 }}>
                                    <div>
                                        <Typography.Text>Max Comps:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.maxComps}
                                            onChange={(e) => setParams({ ...params, maxComps: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Miles Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.milesRange}
                                            onChange={(e) => setParams({ ...params, milesRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Max Months Back:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.maxMonthsBack}
                                            onChange={(e) => setParams({ ...params, maxMonthsBack: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Sq Ft % Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.sqFtPercentRange}
                                            onChange={(e) => setParams({ ...params, sqFtPercentRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Acres % Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.acresPercentRange}
                                            onChange={(e) => setParams({ ...params, acresPercentRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Year Built Range:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={params.yearBuiltRange}
                                            onChange={(e) => setParams({ ...params, yearBuiltRange: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <Button type="primary" onClick={fetchBestCompsData} style={{ marginLeft: 8, alignSelf: 'flex-end' }}>
                                        Calculate
                                    </Button>
                                </Flex>
                            </Collapse.Panel>
                        </Collapse>
                    </>
                }
                open={isModalOpen}
                onCancel={handleModalClose}
                footer={null}
                width="80%"
                style={modalStyle}
                styles={{
                    header: {
                        paddingBottom: 0,
                    },
                    mask: {
                        position: 'fixed'
                    },
                    wrapper: {
                        position: 'fixed'
                    }
                }}
            >
                <div style={{display: 'grid', placeItems: 'center', width: '100%'}}>
                    {bestCompsLoading ? <Spin /> : (!bestCompsList || bestCompsList.length === 0) ? (
                        <div>No comps found</div>
                    ) : (
                        <Table 
                            dataSource={bestCompsList} 
                            rowKey={(record) => record.id}
                            columns={[
                                {
                                    title: 'Address',
                                    dataIndex: 'address',
                                    key: 'address',
                                    render: (value, record) => <Button type='link' onClick={() => handleAddressClick(record.id)}>{value}</Button>
                                },
                                {
                                    title: 'Sale Date',
                                    dataIndex: 'saleDate',
                                    key: 'saleDate',
                                    render: (value) => formatDate(value, false)
                                },
                                {
                                    title: 'Est. Sale Amount',
                                    dataIndex: 'estSaleAmt',
                                    key: 'estSaleAmt',
                                    render: (value) => value ? `$${value.toLocaleString()}` : '-'
                                },
                                {
                                    title: 'Est. Sale Amount/Sq Ft',
                                    key: 'estSaleAmtPerSqFt',
                                    render: (_, record) => {
                                        if (record.estSaleAmt && record.finishedLivingSqFt) {
                                            const perSqFt = record.estSaleAmt / record.finishedLivingSqFt;
                                            return `$${perSqFt.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
                                        }
                                        return '-';
                                    }
                                },
                                {
                                    title: 'Acres',
                                    dataIndex: 'acres',
                                    key: 'acres',
                                    render: (value) => value?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || '-'
                                },
                                {
                                    title: 'Bedrooms',
                                    dataIndex: 'bedrooms',
                                    key: 'bedrooms',
                                },
                                {
                                    title: 'Bathrooms',
                                    dataIndex: 'bathrooms',
                                    key: 'bathrooms',
                                },
                                {
                                    title: 'Age',
                                    key: 'age',
                                    render: (_, record) => {
                                        if (record.yearBuilt) {
                                            return ageInYearsFromYear(record.yearBuilt) + " years";
                                        }
                                        return '-';
                                    }
                                }
                            ]} 
                        />
                    )}

                </div>
            </Modal>
            <Modal
                style={modalStyle}
                open={isAdjustCharacteristicsModalOpen}
                width="80%"
                onCancel={handleAdjustCharacteristicsModalClose}
                footer={null}
                title={
                    <>
                        <Typography.Text style={{fontSize: 18}}>Adjust Characteristics</Typography.Text>
                        <Collapse defaultActiveKey={[]} style={{marginBottom: 16, marginTop: 16}}>
                            <Collapse.Panel header="Parameters" key="1">
                                <Flex wrap="wrap" gap="small" style={{ marginBottom: 16 }}>
                                    <div>
                                        <Typography.Text>Property Sub Type:</Typography.Text>
                                        <Select
                                            options={[
                                                {value: 1, label: "Single Family"},
                                                {value: 2, label: "Condo"},
                                                {value: 3, label: "Mobile w/o Land"},
                                                {value: 4, label: "Recreational"},
                                                {value: 6, label: "Mobile with Land"}
                                            ]}
                                            value={adjustCharacteristicsParams?.propertySubTypeId || ''}
                                            onChange={(newValue : number) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, propertySubTypeId: newValue })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Bedrooms:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.bedrooms || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, bedrooms: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Bathrooms:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.bathrooms || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, bathrooms: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Year Built:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.yearBuilt || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, yearBuilt: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Acres:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.acres || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, acres: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Main Level Sq Ft:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.mainLevelSqFt || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, mainLevelSqFt: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Upper Levels Sq Ft:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.upperLevelsSqFt || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, upperLevelsSqFt: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Above Grade Sq Ft:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.aboveGradeSqFt || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, aboveGradeSqFt: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Finished Basement Sq Ft:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.finishedBasementSqFt || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, finishedBasementSqFt: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Unfinished Basement Sq Ft:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.unfinishedBasementSqFt || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, unfinishedBasementSqFt: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div>
                                        <Typography.Text>Finished Living Sq Ft:</Typography.Text>
                                        <Input
                                            type="number"
                                            value={adjustCharacteristicsParams?.finishedLivingSqFt || ''}
                                            onChange={(e) => setAdjustCharacteristicsParams({ ...adjustCharacteristicsParams, finishedLivingSqFt: parseInt(e.target.value) })}
                                            style={{ width: 120, marginLeft: 8 }}
                                        />
                                    </div>
                                    <Button type="primary" onClick={fetchAdjustCharacteristicsData} style={{ marginLeft: 8, alignSelf: 'flex-end' }}>
                                        Calculate
                                    </Button>
                                </Flex>
                            </Collapse.Panel>
                        </Collapse>
                    </>
                }
            >
                <div style={{display: 'grid', placeItems: 'center', width: '100%'}}>
                    {adjustCharacteristicsLoading ? <Spin /> : (!adjustCharacteristicsSandbox) ? (
                        <div>No Results</div>
                    ) : (
                        <List
                            header = {<Typography.Text style={{fontWeight: 'bold'}}>Component Valuations</Typography.Text>}
                            dataSource={adjustCharacteristicsData(adjustCharacteristicsSandbox)}
                            renderItem={(item) => renderListItem(item, moreButtonValue)}
                        />
                    )}

                </div>
            </Modal>
        </>
    );
};

export default PropertyValuations;