import { RightOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { PropertySummary } from '../../types/PropertySummary.def';
import PropertyCard from '../PropertyCard/PropertyCard';

import { Link, useNavigate } from 'react-router-dom';

const PropertySlider: React.FC<PropertySliderProps> = (props) => {

    const navigate = useNavigate();

    const {emptyMessage, mainMessage, linkMessage, linkURL, queryParams, properties} = props;
    
    const constructUrl = () => {
        if(!!queryParams) {
            return linkURL + "?" + Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join("&");
        }
        return linkURL;
    }

    // on load initially shows empty message. Fix?
    return (
        <>
            <Typography.Title level={4} style={{ margin: 0, marginLeft: 10 }}>
                {!!properties &&properties?.length > 0 ? (
                    <>
                        <span>
                            {mainMessage}
                        </span>
                        <Link to={constructUrl()}>
                            <Button type='link' onClick={() => !!queryParams ? navigate(constructUrl()) : navigate(linkURL)} icon={<RightOutlined />} iconPosition='end'>{linkMessage}</Button>
                        </Link>
                    </>
                ) : emptyMessage}
            </Typography.Title>
            <div className='container'>
                {properties?.map((property) => {
                    return (
                        <PropertyCard key={property.id} property={property}></PropertyCard>
                    )
                })}
            </div>
        </>
    )
}

interface PropertySliderProps {
    emptyMessage: string;
    mainMessage: string;
    linkMessage: string;
    linkURL: string;
    queryParams?: object;
    properties: PropertySummary[];
}

export default PropertySlider;