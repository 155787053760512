import { Typography } from "antd";
import { Property } from "../../../types/Property.def";
import { numberWithCommas } from "../../../shared/shared-functions";


export const OwnershipInfo = (property: Property) => {
    return [
        {
          title: 'Owner Name',
          content: property?.owner || '-'
        },
        {
          title: 'Owner Address',
          content: property?.ownerAddress || '-'
        },
      ];
}

export const MortgageInfo = (property: Property) => {
    return [
        {
          title: 'Est. Loan Date',
          content: property?.origLoanDate ? new Date(property?.origLoanDate).toLocaleDateString() : '-' //  why is the est. loan date one day off for granite meadow (and another one i checked. One day behind)
        },
        {
          title: 'Est. Loan Amount',
          content: property?.origLoanAmt ? '$' + numberWithCommas(property?.origLoanAmt) : '-'
        },
      ];
}

export const LoanToValue = (property: Property) => {
    return [
        {
          title: 'Est. Loan Balance',
          content: property?.currentLoanBalance ? `$${numberWithCommas(property?.currentLoanBalance)}` : '-'
        },
        {
          title: 'Est. Value',
          content: property?.valuation ? `$${numberWithCommas(property?.valuation)}` : '-'
        },
        {
          title: 'LVT',
          content: property?.loanToValue ? `${(property?.loanToValue*100).toFixed(2)}%` : '-'
        },
      ];
}

export const ListPriceToValue = (property: Property) => {
    return [
        {
          title: 'List Price',
          content: property?.listPrice ? '$' + numberWithCommas(property?.listPrice) : '-', // TODO: empty on old trueAVM... should this check if its an active listing or not?
        },
        {
          title: 'Est. Value',
          content: property?.valuation ? '$' + numberWithCommas(property?.valuation) : '-'
        },
        {
          title: 'LPV',
          content: property?.listPriceToValue ? `${(property?.listPriceToValue*100).toFixed(2)}%` : '-'
        },
      ];
}