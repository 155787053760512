import React from 'react';
import { List } from 'antd';
import { WorkspaceParcel } from '../../../types/WorkspaceParcel.def';
import { formatDate } from '../../../shared/shared-functions';

interface WorkspaceParcelDetailProps {
  parcel: WorkspaceParcel;
}

const WorkspaceParcelDetail: React.FC<WorkspaceParcelDetailProps> = ({ parcel }) => {
  const details = [
    { key: 'Address', value: parcel.address || " - " },
    { key: 'Full Address', value: parcel.fullAddress || " - " },
    { key: 'City', value: parcel.city || " - " },
    { key: 'City/State/Zip', value: parcel.cityStateZip || " - " },
    { key: 'State', value: parcel.state || " - " },
    { key: 'Zip Code', value: parcel.zipCode || " - " },
    { key: 'County', value: parcel.county || " - " },
    { key: 'Parcel Number', value: parcel.parcelNumber || " - " },
    { key: 'Property ID', value: parcel.propertyId || " - " },
    { key: 'Entry Type', value: parcel.entryType || " - " },
    { key: 'First Party', value: parcel.firstParty || " - " },
    { key: 'Second Party', value: parcel.secondParty || " - " },
    { key: 'For Sale', value: parcel.isForSale ? 'Yes' : 'No' },
    { key: 'List Number', value: parcel.listNumber || " - " },
    { key: 'List Status', value: parcel.listStatus || " - " },
    { key: 'List Date', value: formatDate(parcel.listDate, true) || " - " },
    { key: 'Listing Change Date', value: formatDate(parcel.listingChangeDateTime, true) || " - " },
    { key: 'Property Date', value: formatDate(parcel.propertyDateTime, true) || " - " },
    { key: 'Trust Deed Date', value: formatDate(parcel.trustDeedDateTime, true) || " - " },
    { key: 'Other Party Date', value: formatDate(parcel.otherPartyDateTime, true) || " - " },
    { key: 'Reconveyance Date', value: formatDate(parcel.reconveyanceDateTime, true) || " - " },
    // { key: 'Workspace Parcel ID', value: parcel.workspaceParcelId },
  ];

  return (
    <List
      size="small"
      bordered
      dataSource={details}
      renderItem={(item) => (
        <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 'bold' }}>{item.key}:</span>
          <span style={{ textAlign: 'right' }}>{item.value}</span>
        </List.Item>
      )}
    />
  );
};

export default WorkspaceParcelDetail;
