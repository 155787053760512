import { Card, Layout, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { FC, useEffect, useState } from 'react';
import { useHttpService } from '../../hooks/UseHttpService';
import { TableCell, TableHeader } from '../../types/Table.def';
import { GenericTable } from '../GenericTable/GenericTable';
import SideMenu from '../SideMenu/SideMenu';

interface LoanDataProps {
}


const LoanData: FC<LoanDataProps> = () => {
    
    const http = useHttpService();
    
    const [saleObject, setSaleObject] = useState<{headers: TableHeader[], rows: TableCell[][]}>({headers: [], rows: []});
    const [titleObject, setTitleObject] = useState<{headers: TableHeader[], rows: TableCell[][]}>({headers: [], rows: []});
    const [recentlyViewed, setRecentlyViewed] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState<string>('title-activity');
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if(activeTab === 'title-activity') {
            if(titleObject.rows.length === 0) {
                getTitleActivity();
            }
        } else {
            if(saleObject.rows.length === 0) {
                getSaleActivity();
            }
        }
    }, [activeTab])

    useEffect(() => {
        if(recentlyViewed.length === 0) {
            let cached = localStorage.getItem("recentlyViewed");
            if(cached) {
                setRecentlyViewed(JSON.parse(cached));
            } else {
                getMostRecentProperties();
            }
        }
    }, [])
    
    const getTitleActivity = async () => {
        setLoading(true);
        //&offset=0
        http.get('/workspace/titleActivity?limit=25')
        .then((res) => {
            setTitleObject(res.data);
        })
        .finally(() => {
            setLoading(false);
        })
    }
    
    const getSaleActivity = async () => {
        setLoading(true);
        //&offset=0
        http.get('/workspace/saleActivity?limit=25')
        .then((res) => {
            setSaleObject(res.data);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const getMostRecentProperties = async () => {
        http.get(`/viewedProperties?offset=0&limit=10&preview=true`)
            .then(res => {
                if (res.success) {
                    setRecentlyViewed(res.data.properties);
                    localStorage.setItem("recentlyViewed", JSON.stringify(res.data.properties));
                }
            })
    }
    
    const items = [
        {
            label: 'Title Activity',
            key: 'title-activity',
            // children: <WorkspaceParcelList results={titleResults} type="title" loading={loading} />
            children: <GenericTable data={titleObject} loading={loading} />
        },
        {
            label: 'Sale Activity',
            key: 'sale-activity',
            // children: <WorkspaceParcelList results={saleResults} type="sale" loading={loading} />
            children: <GenericTable data={saleObject} loading={loading} />
        }
    ]

    return (
        <Layout>
            <SideMenu recentlyViewed={recentlyViewed} />
            <Content style={{padding: '16px 10px'}}>
                <Card style={{width: '98%', marginLeft: 'min(4%, 20px'}}>
                    <Tabs defaultActiveKey={activeTab} onChange={(key) => setActiveTab(key)}  items={items} />
                </Card>
            </Content>
        </Layout>
    );
};

export default LoanData;
