import { Button, Table, Image } from 'antd';
import React from 'react';
import { HomeTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GenericTableProps } from '../../types/Table.def';
import { TableCell } from '../../types/Table.def';

const IS_MOBILE = window.innerWidth < 768;

export const GenericTable: React.FC<GenericTableProps> = ({ data, loading = false }) => {
    const columns = data.headers.map((header, index) => ({
        title: header.text,
        dataIndex: index.toString(),
        key: index.toString(),
        render: (cell: TableCell) => {
            if (index === 0) {
                if (cell.imageUrl) {
                    return (
                        <Image
                            src={cell.imageUrl}
                            alt="Property"
                            fallback='https://trueavm-app-images.s3.us-east-1.amazonaws.com/house_v1.png'
                            style={{
                                width: '90px',
                                height: 'auto',
                                objectFit: 'cover',
                                borderRadius: '10px'
                            }}
                            preview={false}
                        />
                    );
                }
                return <HomeTwoTone style={{ fontSize: 55, marginLeft: '10px' }} />;
            }

            if (cell.internalLinkUrl) {
                return (
                    <Link to={cell.internalLinkUrl}>
                        <Button type="link" style={{ textDecoration: 'underline' }}>
                            {cell.text}
                        </Button>
                    </Link>
                );
            }

            if (cell.externalLinkUrl) {
                return (
                    <a href={cell.externalLinkUrl} target="_blank" rel="noopener noreferrer">
                        {cell.text}
                    </a>
                );
            }

            return cell.text || 'N/A';
        }
    }));

    const dataSource = data.rows.map((row, rowIndex) => ({
        key: rowIndex,
        ...row.reduce((acc, cell, cellIndex) => ({
            ...acc,
            [cellIndex]: cell
        }), {})
    }));

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            size="small"
            pagination={false}
            scroll={{ x: IS_MOBILE ? 500 : undefined }}
        />
    );
};
