import { Flex, Menu, Modal, Space } from "antd";
import { Link, Outlet } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { LogoutModal } from "../Logout/LogoutModal";


export default function Manage() {

    const items = [
        { label: 'Account', key: '/manage/account' },
        { label: 'Value Estimator', key: '/manage/value-estimator' },
        { label: 'Log Out', key: '/logout' },
        // { label: 'Upload a File', key: '/manage/file-upload' },
      ]

      const { isLoggedIn, redirectIfNoUser } = useAppContext();

      useEffect(() => {
        redirectIfNoUser();
      }, [isLoggedIn]);

      const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

      const showModal = () => {
        setIsLogoutModalOpen(true);
      }

    return (
        <>
            <Flex wrap>
                <Menu
                    overflowedIndicator={<MenuOutlined style={{ fontSize: 20 }} />}
                    style={{ maxWidth: 200, borderRadius: "10px", margin: "1rem", overflow: 'auto' }}
                    mode="inline"
                    defaultSelectedKeys={['/manage/account']}
                >
                    {items.map(item => {
                         if (item.key === "/logout")
                            return <Menu.Item key={item.key} onClick={showModal}>{item.label}</Menu.Item>
                        return <Menu.Item key={item.key}><Link to={item.key}>{item.label}</Link></Menu.Item>
                    })}
                </Menu>
                <Space direction="vertical">
                    <Outlet></Outlet>
                </Space>
                <LogoutModal show={isLogoutModalOpen} close={() => setIsLogoutModalOpen(false)} />
            </Flex>
        </>
    );
}
