import React from 'react';
import { PropertyProps } from '../../../types/Property.def';
import { Divider, Descriptions, List, Typography } from 'antd';
import { OwnershipInfo, MortgageInfo, LoanToValue, ListPriceToValue } from './PropertyOwnershipData';
import { renderListItem } from '../PropertyUtil';

const PropertyOwnership: React.FC<PropertyProps> = (props) => {

    const { property } = props;

    const ownershipItems = OwnershipInfo(property);
    const mortgageItems = MortgageInfo(property);
    const loanToValueItems = LoanToValue(property);
    const listPriceToValueItems = ListPriceToValue(property);


    return (
        <div>
            <List
                header = {<Typography.Text style={{fontWeight: 'bold'}}>Ownership Information</Typography.Text>}
                dataSource={ownershipItems}
                renderItem={(item) => renderListItem(item)}
            />
            <List
                header = {<Typography.Text style={{fontWeight: 'bold'}}>Mortgage Information</Typography.Text>}
                dataSource={mortgageItems}
                renderItem={(item) => renderListItem(item)}
            />
            <List
                header = {<Typography.Text style={{fontWeight: 'bold'}}>Loan to Value</Typography.Text>}
                dataSource={loanToValueItems}
                renderItem={(item) => renderListItem(item)}
            />
            <List
                header = {<Typography.Text style={{fontWeight: 'bold'}}>List Price to Value</Typography.Text>}
                dataSource={listPriceToValueItems}
                renderItem={(item) => renderListItem(item)}
            />
        </div>
    );
};

export default PropertyOwnership;