import { createContext, useContext, useState } from 'react';
import { toast_warn } from '../shared/shared-functions';
import { useNavigate } from 'react-router-dom';
import { useHttpService } from '../hooks/UseHttpService';
import { AppContextType } from '../types/AppContextTypes.def';
import { WorkspaceNavTab } from '../types/dtos/WorkspaceNavTab.def';

const AppContext = createContext(null);


export const AppProvider = ({ children }) => {
  
  const storage = localStorage;
  const navigate = useNavigate();
  const http = useHttpService();
  const [allowedMenuItems, setAllowedMenuItems] = useState<WorkspaceNavTab[]>([]);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    return storage.getItem('isLoggedIn') === 'true';
  });

  const setPersistedIsLoggedIn = (value: boolean) => {
    setIsLoggedIn(value);
    storage.setItem('isLoggedIn', value.toString());
  };

  const setPersistedAllowedMenuItems = (value: WorkspaceNavTab[]) => {
    setAllowedMenuItems(value);
    storage.setItem('menuItems', JSON.stringify(value));
  };

  const redirectIfNoUser = () => {
    if (!isLoggedIn) {
      navigate("/login");
      setTimeout(() => {
        toast_warn("Please log in to access this page.");
      }, 1000);
    }
  }

  const logout = async () => {
    const success = await http.serverLogout();
    storage.clear();
    setIsLoggedIn(false);
    navigate('/login');
    if (!success) {
     console.error("failed to log out from server");
    }
  }

  const value: AppContextType = {
    isLoggedIn: isLoggedIn,
    setIsLoggedIn: setPersistedIsLoggedIn,
    allowedMenuItems: allowedMenuItems,
    setAllowedMenuItems: setPersistedAllowedMenuItems,
    redirectIfNoUser: redirectIfNoUser,
    logout: logout
  }

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () : AppContextType => {
  return useContext(AppContext);
};