import { MenuOutlined } from "@ant-design/icons";
import { Input, Layout, Menu, Select, Space } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from "../../context/AppContext";
import './BaseLayout.css';
import { MenuItem } from "../../types/MenuItem.def";
import { LogoutModal } from "../Logout/LogoutModal";
import { WorkspaceNavTab } from "../../types/dtos/WorkspaceNavTab.def";

const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { Option } = Select;

const iconStyle = {height: '40px', width: "auto", marginTop: 10, marginLeft: '-15px'}



const BaseLayout: React.FC = () => {

  /**************************************** Constants ****************************************/
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const { isLoggedIn, allowedMenuItems, setAllowedMenuItems } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [searchType, setSearchType] = useState('address');
  const [loading, setLoading] = useState(false);
  const storage = localStorage;

  const defaultNavTabs : WorkspaceNavTab[] = [
    {
      routePath: "/property-search",
      label: "Properties"
    }
  ]

  const prefix = (
    <Space>
      <Select defaultValue="address" className="search-type-select" value={searchType} onChange={(value) => setSearchType(value)}>
        <Option value="address">Address</Option>
        <Option value="listNumber">List #</Option>
        <Option value="owner">Owner</Option>
        <Option value="taxId">Tax ID</Option>
        <Option value="text">Text</Option>
      </Select>
    </Space>
  );
  
  /**************************************** Effects ****************************************/

  useEffect(() => {
    const menuKeys = allowedMenuItems.map((item) => item.routePath);
    for (let i = 0; i < menuKeys.length; i++) {
      if (menuKeys[i] && menuKeys[i].includes(location.pathname)) {
        setSelectedKey(menuKeys[i]);
        return;
      }
    }
    setSelectedKey(null);
  }, [location, allowedMenuItems]);

  useEffect(() => {
    if ((allowedMenuItems == null || allowedMenuItems.length == 0) && isLoggedIn) {
      const cachedAllowedMenuItems = JSON.parse(storage.getItem('menuItems'));
      setMenuItems(getMenuItems(cachedAllowedMenuItems));
      setAllowedMenuItems(cachedAllowedMenuItems);
    } else if (isLoggedIn){
      setMenuItems(getMenuItems(allowedMenuItems));
    } else {
      setMenuItems(getMenuItems(defaultNavTabs));
    }
  }, [allowedMenuItems])

  /**************************************** Methods ****************************************/

  const getMenuItems = (items : WorkspaceNavTab[]) => {
    return items.map((item : WorkspaceNavTab) => createMenuItem(item));
  }

  const createMenuItem = (navTab : WorkspaceNavTab) : MenuItem => {
      const {routePath, children} = navTab;
      return {
      key: routePath,
      label: navLinkLogic(navTab),
      children: children ? children.map(createMenuItem) : undefined
    }
  };

  const navLinkLogic = (navTab : WorkspaceNavTab) => {
    const {routePath, label, children} = navTab;
    if (routePath === "/logout") {
      return <div onClick={() => setIsLogoutModalOpen(true)}>Log Out</div>;
    }
    return !children || children.length == 0
      ? <Link to={routePath}>{label}</Link> 
      : <>{ label }</>;
  }

  const onSearch: SearchProps['onSearch'] = async (value) => {
    //todo: navigate to property search and pass in the search type somehow
    if(value.length > 3) {
      let url = "/property-search?key=" + searchType + "&value=" + value;
      navigate(url);
    }
  }

  /**************************************** Return ****************************************/

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', padding: '0 25px', background: '#edf6ff', borderBottom: '1px solid #DFE8F0 '}} >
          <div style={{marginRight: 15 }}>
            <Link to="/home"><img src="../../../trueavm.svg" style={iconStyle} alt="trueavm logo" /></Link>
          </div>
          <div style={{minWidth: "50%", maxWidth: "66%"}}>
            <Search addonBefore={prefix} placeholder="Search..." allowClear onClear={() => (window.location.pathname === '/property-search') ? navigate("/property-search") : null } onSearch={onSearch} loading={loading} enterButton style={{ paddingTop: 15, marginRight: 5, width: 'minmax(180, 100%)'}}/>
          </div>
          <Menu
            overflowedIndicator={<MenuOutlined style={{fontSize: 20}} />}
            mode="horizontal"
            selectedKeys={[selectedKey]}
            style={{ flex: 1, minWidth: 0, background: "#edf6ff" }}
            items={menuItems}   
          />
        </Header>
        <Content style={{ padding: '0 10px' }}>
          <div
            className="content"
            style={{
              marginTop: 2,
              paddingBottom: 10
            }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: '2vw' }}>
          TrueAVM ©{new Date().getFullYear()} Created by TrueAVM, LLC
        </Footer>
      </Layout>
      <LogoutModal show={isLogoutModalOpen} close={() => setIsLogoutModalOpen(false)}/>
    </>
  );
};

export default BaseLayout;