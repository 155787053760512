import { useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import { Modal } from "antd";

export const LogoutModal: React.FC<Props> = ({show, close} : Props) => {

    const { logout } = useAppContext();
  
    const handleLogout = () => {
      logout();
      close();
    };    

  return (
      <Modal title="Are you sure you want to log out?" open={show} onOk={handleLogout} onCancel={close} okText="Yes"/>
  );
}

interface Props {
    show : boolean, 
    close : () => void
}