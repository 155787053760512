import { Tooltip, List, Typography } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons";

const gridStyle = {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "minmax(100px, 300px) minmax(80px, 150px)",
    columnGap: "12px",
    rowGap: "12px",
    paddingRight: "18px",
};

export const toolTipTitle = (item) => {
    if(item.info) {
        return (
            <>
                {item.title} 
                <Tooltip placement="right" title={item.info}>
                    <InfoCircleOutlined style={{marginLeft: 5}}/>
                </Tooltip>
            </>
        )
    } else {
        return <>{item.title}</>
    }
}

export const renderListItem = (item, valueItemMapper?:Function) => {
    if (!!!valueItemMapper)
        valueItemMapper = (value) => value.content
    return(
        <List.Item style={{padding: 8}}>
            <div style={gridStyle}>
                <Typography.Text>{toolTipTitle(item)}</Typography.Text>
                {valueItemMapper(item)}
            </div>
        </List.Item>
    )
}