import { CaretDownOutlined, CopyOutlined, ExportOutlined, HomeTwoTone, PrinterOutlined, StarFilled } from '@ant-design/icons';
import type { MenuProps, TabsProps } from 'antd';
import { Button, Card, Dropdown, Flex, Image, Statistic, Tabs, Tag, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useHttpService } from '../../hooks/UseHttpService';
import { fullAddress, googleMapsUrl, numberWithCommas, timeEllapsed_ago_string, toast_error } from '../../shared/shared-functions';
import { MarketConditionsInfo } from '../../types/MarketConditionsInfo.def';
import { Property } from '../../types/Property.def';
import { ExtendedPropertySummary } from '../../types/PropertySummary.def';
import { SimilarPropertyType } from '../../types/SimilarPropertiesTypes.def';
import WorkspaceParcelList from '../LoanData/WorkspaceParcelList/WorkspaceParcelList';
import PrintView from './PrintView/PrintView';
import PropertyCharacteristics from './PropertyCharacteristics/PropertyCharacteristics';
import PropertyOwnership from './PropertyOwnership/PropertyOwnership';
import './PropertyPage.css';
import PropertyValuations from './PropertyValuations/PropertyValuations';
import SimilarProperties from './SimilarProperties/SimilarProperties';
import { WorkspaceParcel } from '../../types/WorkspaceParcel.def';


const PropertyPage: React.FC = () => {

    const { viewPropertyId } = useParams<{ viewPropertyId: string }>();

    const [property, setProperty] = useState<Property>();
    const [marketConditionsInfo, setMarketConditionInfo] = React.useState<MarketConditionsInfo>();
    const [selectedTab, setSelectedTab] = useState("Valuations");
    const [recalculating, setRecalculating] = useState(false);

    const [recentlySold, setRecentlySold] = useState<ExtendedPropertySummary[]>(null);
    const [forSale, setForSale] = useState<ExtendedPropertySummary[]>(null);
    const [neighboringProps, setneighboringProps] = useState<ExtendedPropertySummary[]>(null);

    const [saleActivity, setSaleActivity] = useState<WorkspaceParcel[]>([]);
    const [titleActivity, setTitleActivity] = useState<WorkspaceParcel[]>([]);

    const [searchParams] = useSearchParams();
    const { tab } = Object.fromEntries(searchParams);

    const [titleActivityLoading, setTitleActivityLoading] = useState(false);
    const [saleActivityLoading, setSaleActivityLoading] = useState(false);

    const loadSimilarProperties = (type : SimilarPropertyType) => {
        http.get(`/property/comps/${type}?id=${property.id}&limit=10`).then(res => {
            if (res.success) {
                switch (type) {
                    case SimilarPropertyType.Sold:
                        setRecentlySold(res.data);
                        break;
                    case SimilarPropertyType.ForSale:
                        setForSale(res.data);
                        break;
                    case SimilarPropertyType.NearBy:
                        setneighboringProps(res.data);
                        break;
                }
            } else {
                toast_error("Cannot load similar properties");
            }
        })
    };

    const http = useHttpService();
    
    const contentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({ contentRef });

    const IS_MOBILE = window.innerWidth < 768;

    const tabs: TabsProps['items'] = [
        {
            key: "Valuations",
            label: "Valuations",
        },
        {
            key: "Details",
            label: "Details",
        },
        {
            key: "Title-History",
            label: "Title History",
        },
        {
            key: "Sale-History",
            label: "Sale History",
        },
        // {
        //     key: "Market-Conditions",
        //     label: "Market Conditions",
        // },
        {
            key: "Similar-Properties",
            label: "Similar Properties",
        },
    ]

    useEffect(() => {
        setRecalculating(true);
        _setTab("Valuations");
        setSaleActivity([]);
        setTitleActivity([]);
        
        http.get(`/property?id=${viewPropertyId}`).then(res => {
            if (res.success) {
                setProperty(res.data);
            } else {
                //TODO: property not found
            }
        })
        http.get(`/property/marketConditionInfo?propertyId=${viewPropertyId}`).then(res =>{
            if(res.success) {
                setMarketConditionInfo({...res.data, propertyId: viewPropertyId})
            } else {
                //TODO: property not found
                console.log(res.message);
            }
        });
        setRecalculating(false);
    }, [viewPropertyId, http])

    useEffect(() => {
        if (tab) {
            _setTab(tab);
        }
    }, [tab])

    const _setTab = (tab: string) => {
        setSelectedTab(tab);
        if (tab === "Sale-History") {
            if(saleActivity.length === 0) {
                loadSaleActivity();
            }
        }
        if (tab === "Title-History") {
            if(titleActivity.length === 0) {
                loadTitleActivity();
            }
        }
    }

    const loadSaleActivity = () => {
        setSaleActivityLoading(true);
        http.get(`/property/parcelListings?propertyId=${viewPropertyId}`).then(res => {
            if (res.success) {
                setSaleActivity(res.data);
            } else {
                toast_error("Cannot load sale activity");
            }
        }).finally(() => {
            setSaleActivityLoading(false);
        });
    }

    const loadTitleActivity = () => {
        setTitleActivityLoading(true);
        http.get(`/property/titleEntries?propertyId=${viewPropertyId}`).then(res => {
            if (res.success) {
                setTitleActivity(res.data);
            } else {
                toast_error("Cannot load title activity");
            }
        }).finally(() => {
            setTitleActivityLoading(false);
        });
    }

    const recalculate = () => {
        if (!!property) {
            setRecalculating(true);
            http.get(`/property?id=${viewPropertyId}&calculate=true`).then(res => {
                if (res.success) {
                    setProperty({ ...res.data });
                } else {
                    //TODO: error
                }
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setRecalculating(false);
            })
        }
    }

    const copyAddress = () => {
        if (property) {
            navigator.clipboard.writeText(fullAddress(property?.address, property?.city, property?.stateAbbrev, property?.zipCode));
        }
    }

    const addressMenuItems: MenuProps['items'] = [
        {
            key: '0',
            label: 'Copy Address',
            icon: <CopyOutlined />,
            onClick: () => copyAddress(),
        },  
        {
            key: '1',
            label: 'Print',
            icon: <PrinterOutlined />,
            onClick: () => handlePrint(),
        },
        {
            key: '2',
            label: 'Open in Google Maps',
            icon: <ExportOutlined />,
            onClick: () => window.open(googleMapsUrl(property?.address, property?.city, property?.stateAbbrev, property?.zipCode), '_blank'),
        },
    ];

    return (
        <>
            <div ref={ contentRef } className='showOnPrint'>
                <PrintView property={ property } marketConditionsInfo={marketConditionsInfo} similarSold={recentlySold} similarForSale={forSale} similarNearBy={neighboringProps}/>
            </div>
            <div key={viewPropertyId} style={{ padding: "0 0px 20px 0px", overflow: 'hidden' }}>
                <div className='main-grid'>
                    <Flex vertical align="flex-end" justify="space-between" style={{ padding: "0 10px 0 0", paddingTop: 0 }}>
                        <Flex justify="space-between" style={{width: '100%'}}>
                            <Flex align="center" justify="flex-start" style={{ width: '100%', marginBottom: 12 }}>
                                <Flex align='center' justify='space-between'>
                                    {!IS_MOBILE && (
                                        <Image src={property?.primaryPhotoUrl} fallback='https://trueavm-app-images.s3.us-east-1.amazonaws.com/house_v1.png' alt="Property Image" width={'8vw'} style={{ borderRadius: 6, maxWidth: '150px', marginTop: 10 }}/>
                                    )}
                                </Flex>
                                <Flex align='flex-start' style={{marginLeft: 'max(16px, 3%)', width: '100%'}} vertical>
                                    <Typography.Title level={2} className="titleAddress"
                                        style={{ 
                                            color: '#001529', 
                                            marginBottom: 5, 
                                            cursor: 'pointer',
                                            marginTop: 8
                                        }} 
                                        >
                                        {fullAddress(property?.address, property?.city, property?.stateAbbrev, property?.zipCode)}
                                    </Typography.Title>
                                    {property?.isForSale && (
                                        <Flex className="margin-flex" align='center' style={{backgroundColor: 'rgb(230 244 255)', padding: 10, borderRadius: 10}}>
                                            <Typography.Text className="property-label">For Sale: </Typography.Text>
                                            <Statistic style={{marginRight: 20}} className="property-value" value={property?.listPrice || "-"} prefix="$"/>
                                            <Typography.Text className="property-label">Deal Rating: </Typography.Text>
                                            <Tag color="yellow" className="deal-rating-tag" icon={<StarFilled />}>
                                                {property?.dealStars || "-"}
                                            </Tag>
                                        </Flex>
                                    )}
                                    <Flex style={{marginTop: 4}} align='center'>
                                        <Typography.Text className="property-label">Estimated Value: </Typography.Text>
                                        <div style={{cursor: 'pointer'}} onClick={() => _setTab("Valuations")}>
                                            <Statistic className="property-value" value={numberWithCommas(property?.valuation, 0) || "-"} prefix={(property?.valuation ? "$" : null)} />
                                        </div>
                                        <div className="property-label-small">(Calculated {timeEllapsed_ago_string(property?.recalcDateTime)}<Button type='link' className="recalc-button-small" onClick={recalculate} loading={recalculating}>Recalculate </Button>)</div>
                                    </Flex> 
                                </Flex>
                            </Flex>
                            {!IS_MOBILE && (
                                <Flex align='center' justify='flex-end'>
                                    <Dropdown menu={{ items: addressMenuItems }} trigger={['click']}>
                                        <Button type='primary'>Actions <CaretDownOutlined /></Button>
                                    </Dropdown>
                                </Flex>
                            )}
                        </Flex>
                        {IS_MOBILE && (
                            <Flex align='center' justify='center' style={{ width: '100%' }}>
                            <Dropdown menu={{ items: addressMenuItems }} trigger={['click']}>
                                <Button style={{width: '100%', maxWidth: 400}} type='primary'>Actions</Button>
                            </Dropdown>
                        </Flex>
                        )}
                        <br></br>
                        <Card style={{ width: "100%" }}>
                            {/* <Segmented onChange={(e) => _setTab(e)} value={selectedTab} size="large" options={['Listing / Characteristics', 'Valuations', 'Ownership / Title', 'Market Conditions', 'Similar Properties']} /> */}
                            <Tabs 
                                activeKey={selectedTab}
                                onChange={(e) => _setTab(e)}
                                size='small'
                                items={tabs}
                            />
                            {
                                selectedTab === "Details" ? (
                                    <PropertyCharacteristics property={property} marketConditionsInfo={marketConditionsInfo}></PropertyCharacteristics>
                                ) : selectedTab === "Valuations" ? (
                                    <PropertyValuations property={property}></PropertyValuations>
                                ) : selectedTab === "Title-History" ? (
                                    <WorkspaceParcelList results={titleActivity} type="title" loading={titleActivityLoading}></WorkspaceParcelList>
                                ) : selectedTab === "Sale-History" ? (
                                    <WorkspaceParcelList results={saleActivity} type="sale" loading={saleActivityLoading}></WorkspaceParcelList>   
                                ) 
                                : selectedTab === "Similar-Properties" ? (
                                    <SimilarProperties recentlySold={recentlySold} forSale={forSale} neighboring={neighboringProps} propertyId={viewPropertyId} setSimilarProps={loadSimilarProperties}></SimilarProperties>
                                ) : (
                                    <div style={{ height: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                                        <br></br>
                                        <Typography.Title className='light-text'>-</Typography.Title>
                                        <br></br>
                                        <p className='sub-text'>Select a tab to view information about this proprety.</p>
                                    </div>
                                )
                            }
                        </Card>
                        <br></br>
                        <Typography.Text className='sub-text'>
                            {/* TODO: this text should be updated */}
                            Local service is provided by our preferred broker, True Realty. By logging on to trueavm.com, you are authorizing TrueAVM to refer you to True Realty for real estate brokerage services, and TrueAVM may receive a referral fee or other valuable consideration for such referral.
                            The information being provided by Wasatch Front Regional MLS is for the consumer's personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumer may be interested in purchasing. Any information relating to real estate for sale referenced on this web site comes from the Internet Data Exchange (IDX) program of the Wasatch Front Regional MLS. True Realty is not a Multiple Listing Service (MLS), nor does it offer MLS access. This website is a service of True Realty, a broker participant of Wasatch Front Regional MLS. This web site may reference real estate listing(s) held by a brokerage firm other than the broker and/or agent who owns this web site.
                            The accuracy of all information, regardless of source, including but not limited to open house information, square footages and lot sizes, is deemed reliable but not guaranteed and should be personally verified through personal inspection by and/or with the appropriate professionals. The data contained herein is copyrighted by Wasatch Front Regional MLS and is protected by all applicable copyright laws. Any unauthorized dissemination of this information is in violation of copyright laws and is strictly prohibited.
                            Copyright 2014 Wasatch Front Regional MLS. All rights reserved.
                        </Typography.Text>
                    </Flex>
                </div>
            </div>
        </>
    )
}

export interface PropertyProps {
    propertyId: number;
}

export default React.memo(PropertyPage);
