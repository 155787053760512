import { RightOutlined } from '@ant-design/icons';
import { Button, Divider, List, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useHttpService } from '../../../hooks/UseHttpService';
import { MarketConditionsDetails } from '../../../types/MarketConditions.def';
import { PropertyMarketConditionsProps } from '../../../types/Property.def';
import MarketConditionDetail from '../../MarketConditionDetail/MarketConditionDetail';
import { Forcast, Statistics } from '../MarketConditions/MarketConditionsData';
import { renderListItem } from "../PropertyUtil";
import { propertyCharacteristicsData } from './PropertyCharacteristicsData';

const PropertyCharacteristics: React.FC<PropertyMarketConditionsProps> = (props) => {

    const { property } = props;
    
    const characteristics = propertyCharacteristicsData(property);

    const { marketConditionsInfo: marketConditionInfo } = props;

    const http = useHttpService()

    const forcast = Forcast(marketConditionInfo);
    const statistics = Statistics(marketConditionInfo);

    const [loading, setLoading] = useState(false);
    const [marketConditions, setMarketConditions] = useState<MarketConditionsDetails>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingType, setLoadingType] = useState<string | null>(null);

    const fetchData = async (type: string) => {
        type = type.toLowerCase();
        setLoadingType(type);
        let url = `${type}/marketConditionDetail`;
        switch (type.toLowerCase()) {
            case 'radius': {
                url += `?propertyId=${marketConditionInfo.propertyId}`;
                url = url.replace(type, 'property');
                break;
            } case 'subdivision': {
                url += `?subdivisionId=${marketConditionInfo.subdivisionId}`;
                break;
            } case 'neighborhood': {
                url += `?neighborhoodId=${marketConditionInfo.neighborhoodId}`;
                break;
            } case 'community': {
                url += `?mlsAreaId=${marketConditionInfo.communityId}`;
                url = url.replace(type, 'mlsArea');
                break;
            } default: {
                break;
            }
        }
        http.get(url).then(res => {
            setMarketConditions(res.data);
            setIsModalOpen(true);
        }).finally(() => {
            setLoadingType(null);
        });
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
        setMarketConditions(undefined);
    };

    const moreButtonValue = (item) => {
        if (item.content != null && item.content != '-') {
            const buttonType = item.title.toLowerCase();
            return (
                <Button
                    onClick={() => fetchData(item.title)}
                    size='small'
                    type='link'
                    loading={loadingType === buttonType}
                    style={{ justifyContent: "left", paddingLeft: "0px", width: 'fit-content' }}
                >
                    {item.content}<RightOutlined />
                </Button>
            )
        }
        return item.content
    }

    const schools = [
        {
            title: 'School District',
            content: property?.schoolDistrict || '-'
          },
          {
            title: 'High School',
            content: property?.highSchool || '-'
          },
          {
            title: 'Middle School',
            content: property?.middleSchool || '-'
          },
          {
            title: 'Elementary School',
            content: property?.elementarySchool || '-'
          }
    ]

    return (
        <div>

            <List
                header={<Typography.Text style={{ marginBottom: "0px", fontWeight: 'bold' }}>Property Details</Typography.Text>}
                dataSource={characteristics}
                renderItem={(item) => renderListItem(item)}
            />
            <List
                header={<Typography.Text style={{ marginBottom: "0px", fontWeight: 'bold' }}>Market Conditions</Typography.Text>}
                dataSource={forcast}
                renderItem={(item) => renderListItem(item)}
            />
            <List
                header={<Typography.Text style={{ fontWeight: '500', color: 'grey', fontSize: '14px' }}>Statistics</Typography.Text>}
                dataSource={statistics}
                renderItem={(item) => renderListItem(item, moreButtonValue)}
            />
            <Divider style={{margin: '4px 0'}}/>
            <List
                dataSource={schools}
                renderItem={(item) => renderListItem(item)}
            />
            <Modal
                title="Market Conditions"
                open={isModalOpen}
                onCancel={handleModalClose}
                footer={null}
                width="80%"
                style={{ maxWidth: '1000px', minWidth: '350px' }}
                styles={{
                    body: {
                        maxHeight: 'calc(100vh - 200px)',
                        overflowY: 'auto'
                    }
                }}
            >
                <MarketConditionDetail marketConditions={marketConditions} />
            </Modal>
        </div>
    );
};

export default PropertyCharacteristics;