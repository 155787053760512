import axios, { AxiosInstance } from 'axios';
import { toast_warn } from '../shared/shared-functions';
import { RestResponse, emptyRestResponse } from '../types/RestResponse.def';
import { REST_BASE_URL } from '../util/constants';

export default class HttpService {

    private instance: AxiosInstance;

    private storage = localStorage;

    private pendingRequests: Map<string, Function> = new Map();

    constructor() {
        axios.defaults.withCredentials = true;
        this.instance = axios.create({
            baseURL: REST_BASE_URL,
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        this.initInterceptors();
    }

    get defaultHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };
    }

    serverLogout = async () => {
        this.storage.clear();
        const result = await this.post('/authentication/logout', null, { withCredentials: true });
        return result.success;
    }

    async get(url, data = null, customHeaders = {}, fileExport: boolean = false): Promise<RestResponse> {
        let result: RestResponse = emptyRestResponse();
        const { request, cancel } = this.request('get', url, data, customHeaders);
        
        await request.then(response => {
            if (fileExport) {
                this.handleFileDownload(response);
            } else {
                result = response.data;
                if (!!result && !result.success) {
                    this.handleError(result);
                    throw new Error(result.message);
                }
            }
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
        });
        return result;
    }

    async post(url, data, customHeaders = {}, fileExport: boolean = false): Promise<RestResponse> {
        let result: RestResponse = emptyRestResponse();
        const { request, cancel } = this.request('post', url, data, customHeaders);
        
        await request.then(response => {
            if (fileExport) {
                this.handleFileDownload(response);
            } else {
                result = response.data;
                if (!!result && !result.success) {
                    this.handleError(result);
                    throw new Error(result.message);
                }
            }
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
        });
        return result;
    }

    async put(url, data, customHeaders = {}, fileExport: boolean = false): Promise<RestResponse> {
        let result: RestResponse = emptyRestResponse();
        const { request, cancel } = this.request('put', url, data, customHeaders);
        
        await request.then(response => {
            if (fileExport) {
                this.handleFileDownload(response);
            } else {
                result = response.data;
                if (!!result && !result.success) {
                    this.handleError(result);
                    throw new Error(result.message);
                }
            }
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
        });
        return result;
    }

    async delete(url, customHeaders = {}): Promise<RestResponse> {
        let result: RestResponse = emptyRestResponse();
        const { request, cancel } = this.request('delete', url, null, customHeaders);
        //I'm unsure of what to do with the 'cancel' part of this.
        await request.then(response => {
            result = response.data
            if (!!result) {
                if (!result.success) {
                    this.handleError(result);
                    throw new Error(result.message);
                }
            }
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            }
            //handle other errors if necessary
        })
        return result;
    }

    request(method, url, data = null, customHeaders = {}) {
        const headers = { ...this.defaultHeaders, ...customHeaders };
        const source = axios.CancelToken.source();

        this.pendingRequests.set(url, source.cancel);

        let config: any = {
            method,
            url,
            headers,
            cancelToken: source.token,
            withCredentials: true,
        };

        if (data) {
            config.data = data;
        }

        return {
            request: this.instance(config).finally(() => {
                this.pendingRequests.delete(url);
            }),
            cancel: source.cancel
        };
    }

    public cancelRequestsByUrlPattern(pattern: string) {
        Array.from(this.pendingRequests.entries()).forEach(([url, cancelFn]) => {
            if (url.includes(pattern)) {
                cancelFn('Request cancelled by pattern match');
                this.pendingRequests.delete(url);
            }
        });
    }

    async refreshToken() {
        try {
            const response = await this.instance.post('/authentication/refresh-token');
            return response;
        } catch (error) {
            console.error('Error refreshing token', error);
            throw error;
        }
    }

    private async handleError(result: RestResponse) {
        if (result.message) {
            // toast_warn(result.message);
        } else {
            console.error(result)
        }
    }

    private initInterceptors() {
        this.instance.interceptors.response.use(
            response => response,
            async error => {     
                if(error.response && error.response.status === 401) {
                    document.location.href = "/login?message=unauthorized";
                    await this.serverLogout();
                }
                return Promise.reject(error);
            }
        );
    }

    private handleFileDownload(response: any) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        
        // Try to get filename from response headers, default to 'download.csv' if not found
        const filename = response.headers['content-disposition']
            ?.split('filename=')[1]
            ?.replace(/"/g, '') 
            || 'download.csv';
        
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
}