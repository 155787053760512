import { Property } from "../../../types/Property.def";
import { Button } from "antd"
import { RightOutlined } from "@ant-design/icons";

export const propertyCharacteristicsData = (property: Property) => {
    return [
        {
          title: 'Property Type',
          content: property?.propertyTypeId || "-"
        },
        {
          title: 'Year Built',
          content: property?.yearBuilt || "-"
        },
        {
          title: 'Sq Ft',
          content: property?.finishedLivingSqFt || "-"
        },
        {
          title: 'Beds',
          content: property?.bedrooms || "-",
        },
        {
          title: 'Baths',
          content: property?.bathrooms || "-",
        },
        {
          title: 'Lot Size',
          content: property?.acres || "-",
        },
      ];
}

export const propertyListingInformation = (property: Property) => {
  return [
      {
        title: 'List Status', 
        content: property?.listStatus || "-"
      },
      {
        title: 'List Date',
        content: property?.listDate_listing ? new Date(property.listDate_listing).toLocaleDateString() : "-"
      },
      {
        title: 'MLS #',
        content: 
          property?.listNumber ?
            <Button
              style={{justifyContent: 'left', paddingLeft:"0", width: 'fit-content'}}
              type="link"
              onClick={() => window.open(property?.listingUrl)}
            >
              {property?.listNumber}<RightOutlined />
            </Button> : "-"
      },
      {
        title: 'Source',
        content: property?.listingService || '-'
      },
      {
        title: 'Listing provided by',
        content: property?.listingOffice || '-'
      },
    ];
}