import { Button, Table } from 'antd';
import React from 'react';
import { HomeTwoTone } from '@ant-design/icons';
import { ExtendedPropertySummary } from '../../types/PropertySummary.def';
import { formatNumber } from '../../shared/shared-functions';
import { Link } from 'react-router-dom';

interface PropertySummaryTableProps {
  properties: ExtendedPropertySummary[];
  columns?: (keyof ExtendedPropertySummary)[];
  loading?: boolean;
}

const DEFAULT_COLUMNS: (keyof ExtendedPropertySummary)[] = ['primaryPhotoUrl', 'address', 'city', 'stateAbbrev', 'zipCode', 'valuation'];

//use this to map custom column header titles
const COLUMN_TITLES: Partial<Record<keyof ExtendedPropertySummary, string>> = {
  primaryPhotoUrl: ' ',
  address: 'Address',
  city: 'City',
  stateAbbrev: 'State',
  zipCode: 'ZIP',
  valuation: 'Est. Value'
};

const IS_MOBILE = window.innerWidth < 768;

export const PropertySummaryTable: React.FC<PropertySummaryTableProps> = ({ 
  properties, 
  columns = DEFAULT_COLUMNS,
  loading = false
}) => {
  const tableColumns = columns.map(col => ({
    title: COLUMN_TITLES[col] || col.toString().charAt(0).toUpperCase() + col.toString().slice(1),
    dataIndex: col,
    key: col,
    render: (value: any, record: any) => {
      switch (col) {
        case 'primaryPhotoUrl':
          return value 
            ? <img 
                src={value} 
                alt="Property" 
                style={{ 
                  width: 'auto', 
                  height: '55px', 
                  objectFit: 'cover', 
                  borderRadius: '10px' 
                }} 
              />
            : <HomeTwoTone style={{fontSize: 55, marginLeft: '10px'}}/>;
        case 'valuation':
          return value ? `$${formatNumber(value)}` : 'N/A';
        case 'address':
          return <Link to={`/property-search/view/${record.key}`}><Button style={{textDecoration: 'underline'   }} type='link'>{value}</Button></Link>;
        default:
          return value;
      }
    }
  }));

  const dataSource = properties.map((property) => ({
    key: property.id,
    ...columns.reduce((acc, col) => ({
      ...acc,
      [col]: property[col]
    }), {})
  }));

  return (
    <Table 
      dataSource={dataSource}
      columns={tableColumns}
      loading={loading}
      size='small'
      pagination={false}
      scroll={{ x: IS_MOBILE ? 500 : undefined }}
    />
  );
};
