import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import Home from "./components/Home/home";
import Login from "./components/Login/login";
import Manage from "./components/Manage/Manage";
import ManageAccount from "./components/Manage/ManageAccount/ManageAccount";
import NotFound from "./components/NotFound/NotFound";
import PropertyPage from "./components/Property/PropertyPage";
import PropertySearch from "./components/PropertySearch/PropertySearch";
import ValueEstimator from "./components/ValueEstimator/ValueEstimator";
import { AppProvider } from "./context/AppContext";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import LoanData from "./components/LoanData/LoanData";
        
//try to add loader to login route (redirectIfUser)? loader={redirectIfUser}

export default (
    <BrowserRouter>
        <AppProvider>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/" element={<Login />} />

                <Route element={<BaseLayout />}>
                    <Route path={"/home"} element={<Home />} />
                    <Route path={"/property-search/:simProp?/:propertyId?"} element={<PropertySearch />} >
                        <Route path={"view/:viewPropertyId"} element={<PropertyPage />} />
                    </Route>
                    <Route path={"/loans"} element={<LoanData />} />
                    <Route path={"/manage"} element={<Manage />} >
                        <Route index path={"/manage/account"} element={<ManageAccount />} />
                        <Route path={'/manage/file-upload'} element={<NotFound />} />
                        <Route path={'/manage/value-estimator'} element={<ValueEstimator />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </AppProvider>
    </BrowserRouter>
)