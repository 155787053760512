import { HistoryOutlined, LeftOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Menu, Tooltip } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fullAddress } from '../../shared/shared-functions';
import { ExtendedPropertySummary } from '../../types/PropertySummary.def';

interface SideMenuProps {
    recentlyViewed: ExtendedPropertySummary[];
    customMenuRender?: (mode: "horizontal" | "inline", collapsed: boolean, setCollapsed: (collapsed: boolean) => void) => React.ReactNode;
}

export default function SideMenu({ recentlyViewed, customMenuRender }: SideMenuProps): JSX.Element {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const IS_MOBILE = window.innerWidth < 768;

    const defaultMenuRender = (mode: "horizontal" | "inline") => (
        <Menu
            defaultOpenKeys={IS_MOBILE ? [] : ['recentlyViewed']}
            selectedKeys={recentlyViewed
                .map((item, i) => location.pathname === `/property-search/view/${item.id}` ? i.toString() : null)
                .filter(Boolean)}
            style={{
                maxWidth: mode === "horizontal" ? '100%' : 200,
                borderRadius: "10px",
                margin: mode === "horizontal" ? "0.5rem 0" : "1rem",
                overflow: 'auto',
                fontSize: mode === "horizontal" ? '14px' : 'inherit',
            }}
            mode={mode}
            items={[
                {
                    key: 'home',
                    icon: <LeftOutlined />,
                    label: 'Back',
                    onClick: () => navigate(-1),
                },
                ...(mode === "inline" ? [{
                    key: 'collapse',
                    icon: collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
                    label: collapsed ? 'Expand' : 'Collapse',
                    onClick: () => setCollapsed(!collapsed),
                }] : []),
                {
                    key: 'recentlyViewed',
                    icon: <HistoryOutlined />,
                    label: 'Recent',
                    children: [
                        ...recentlyViewed?.map((item, i) => ({
                            key: i,
                            label: (
                                <Tooltip
                                    style={{width: "100%"}}
                                    title={fullAddress(item.address, item.city, item.stateAbbrev, item.zipCode)}
                                    mouseLeaveDelay={0}
                                    placement="right"
                                >
                                    <Link to={`/property-search/view/${item.id}`}>
                                        <span style={{overflow: 'hidden', width: '100%'}}>{fullAddress(item.address, item.city, item.stateAbbrev, item.zipCode)}</span>
                                    </Link>
                                </Tooltip>
                            ),
                        })),
                        {
                            key: 11,
                            label: (
                                <Link to={`/property-search?recent=true`}>
                                    <span style={{cursor: 'pointer', color: '#1677ff'}}>
                                        View All Recent
                                    </span>
                                </Link>
                            ),
                        }
                    ]
                },
            ]}
        />
    );

    const renderContent = (mode: "horizontal" | "inline") => {
        return customMenuRender ? 
            customMenuRender(mode, collapsed, setCollapsed) : 
            defaultMenuRender(mode);
    };

    return IS_MOBILE ? (
        <div>
            {renderContent("horizontal")}
        </div>
    ) : (
        <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: '#f5f5f5' }}>
            {renderContent("inline")}
        </Sider>
    );
}