import React from 'react';
import { List, Typography, Statistic } from 'antd';
import { ValuationListData } from '../../../types/PropertyValuationsTypes.def';
import { toolTipTitle } from '../PropertyUtil';
import './PropertyValuations.css'
import './ValuationList.css';

 
const ValuationList: React.FC<ValuationListProps> = ({data}) => {

    if(!!!data)
        return(<></>);
    const {rows: content, header, columnCount} = data
    
    return (
        <List 
            header={
                !!!header ? null :
                <div className="valuations-grid">
                    {header.map((element) => <Typography.Text strong>{element}</Typography.Text>)}
                </div>
            }
            dataSource={content}
            renderItem={
                (item) => (
                    <List.Item>
                        <div style={{display:'grid',  width:'100%', gridTemplateColumns: `repeat(${columnCount || header.length || 'auto-fit'}, minmax(90px, 1fr))`}}>
                            <div style={{paddingLeft:'5px', paddingRight:'5px'}}>{toolTipTitle({title:item.label, info:item.info})}</div>
                            {
                                item?.values.map(({value, suffix}) => {
                                    return <Statistic 
                                        valueStyle={{ fontSize: 15 }}  
                                        value={ value } 
                                        prefix={ value === "-" || suffix ? null : '$'} 
                                        suffix={suffix}
                                        precision={suffix ? 2 : 0} 
                                    />
                                })
                            }
                        </div>
                    </List.Item>
                )
            }
        />
    );
};

interface ValuationListProps {
    data: ValuationListData;
}

export default ValuationList;