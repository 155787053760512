import {
    DownloadOutlined,
    FilterOutlined
} from '@ant-design/icons';
import { Button, Layout, Modal, Pagination, PaginationProps, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';

import { Header } from 'antd/es/layout/layout';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { useHttpService } from '../../hooks/UseHttpService';
import { camelCaseToNormalString, toast_error } from '../../shared/shared-functions';
import { emptyPropertySearchDto, PropertySearchDto } from '../../types/PropertyFilter.def';
import { ExtendedPropertySummary } from '../../types/PropertySummary.def';
import { RestResponse } from '../../types/RestResponse.def';
import { SimilarPropertyType } from '../../types/SimilarPropertiesTypes.def';
import PropertyTextSearch from '../PropertyTextSearch/PropertyTextSearch';
import SideMenu from '../SideMenu/SideMenu';
import './PropertySearch.css';
import PropertySearchFilters from './PropertySearchFilters/PropertySearchFilters';
import { GenericTable } from '../GenericTable/GenericTable';
import { TableCell, TableHeader } from '../../types/Table.def';

export default function PropertySearch() {

    const PAGE_SIZE = 30;

    const navigate = useNavigate();
    const http = useHttpService();
    const { redirectIfNoUser } = useAppContext();

    const [recentlyViewed, setRecentlyViewed] = useState<ExtendedPropertySummary[]>([]);

    const [open, setOpen] = useState(false);
    const [filters, _setFilters] = useState<PropertySearchDto>(emptyPropertySearchDto());
    const [filterCount, setFilterCount] = useState(0);
    const [headers, setHeaders] = useState<TableHeader[]>([]);
    const [rowsMap, setRowsMap] = useState<{[key: number]: TableCell[][]}>({1: []});
    const [total, setTotal] = useState(0);
    const [pageNumber, _setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState<{ key: string, value: string }>({ key: null, value: null });
    const [searchParams] = useSearchParams();
    const { key, value, propertyId, simProp, recent } = Object.fromEntries(searchParams);
    const location = useLocation();

    const isLoadingRef = useRef(false);

    const setRowsAtIndex = (index: number, rows: TableCell[][]) => {
        setRowsMap(prev => ({ ...prev, [index]: rows }));
    }

    const setPageNumber = (index: number) => {
        if (index < 1) return;
        if(!rowsMap[index]) {
            setRowsAtIndex(index, []);
        }
        _setPageNumber(index);
    }

    useEffect(() => {
        redirectIfNoUser();
    }, [])

    useEffect(() => {
        setPageNumber(1);
        handleParams();
    }, [key, value, propertyId, simProp, recent])

    useEffect(() => {
        const timer = setTimeout(() => {
            getMostRecentProperties();
        }, 500);
        return () => clearTimeout(timer);
    }, [http, location.pathname])


    const handleParams = async (currentPage: number = 1) => {
        if (isLoadingRef.current) return;
        isLoadingRef.current = true;
        setLoading(true);
        try {
            if ((!!propertyId && !!simProp) || recent || (!!key && !!value)) {
                setFilters(emptyPropertySearchDto());
                if (!!propertyId && !!simProp) {
                    await getSimilarProperties(simProp as SimilarPropertyType);
                } else if (recent) {
                    await getAllRecentProperties(currentPage);
                } else if (!!key && !!value) {
                    setSearchCriteria({ key, value });
                    await searchByText(key, value);
                }
            } else {
                if(!(rowsMap[currentPage] && rowsMap[currentPage].length > 0)) {
                    let savedFilters = JSON.parse(localStorage.getItem("propertySearchFilters"));
                    if (!savedFilters) {
                        const res: RestResponse = await http.get("/user/propertySearch");
                        if (res.success) {
                            savedFilters = res.data
                        }
                    }
                    let newFilters = emptyPropertySearchDto();
                    if (savedFilters && hasValidFilters(savedFilters)) {
                        newFilters = { ...newFilters, ...savedFilters };
                        setFilters(newFilters);
                        await doAdvancedSearch({ ...newFilters, offset: (currentPage - 1) * PAGE_SIZE, limit: PAGE_SIZE }, currentPage);
                    }
                }
            }
        } finally {
            setLoading(false);
            isLoadingRef.current = false;
        }
    }

    const allowPagination = (): boolean => {
        if ((!!propertyId && !!simProp) || recent === 'true' || (!!key && !!value)) {
            return true;
        }
        return hasValidFilters();
    }

    const hasValidFilters = (_filters: PropertySearchDto = filters): boolean => {
        for (const [key, value] of Object.entries(_filters)) {
            if (value !== null &&
                value !== undefined &&
                value !== '' &&
                !(Array.isArray(value) && value.length === 0) &&
                !(typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)) {
                return true;
            }
        }
        return false;
    }

    const allowExport = (): boolean => {
        return !!key && !!value || hasValidFilters()
    }

    const getAllRecentProperties = async (currentPage: number) => {
        http.get(`/viewedProperties?offset=${(currentPage - 1) * PAGE_SIZE}&limit=${PAGE_SIZE}`)
            .then(res => {
                if (res.success) {
                    setTotal(res.data.total);
                    setHeaders(res.data.headers);
                    setRowsAtIndex(currentPage, res.data.rows);
                }
            })
    }

    const getMostRecentProperties = async () => {
        http.get(`/viewedProperties?offset=0&limit=10&preview=true`)
            .then(res => {
                if (res.success) {
                    setRecentlyViewed(res.data.properties);
                    localStorage.setItem("recentlyViewed", JSON.stringify(res.data.properties));
                }
            })
    }

    const getSimilarProperties = async (simPropType: SimilarPropertyType) => {
        let res: RestResponse = await http.get(`/property/comps/${simPropType}?id=${propertyId}&limit=${PAGE_SIZE}`);
        if (res.success) {
            setTotal(res.data.length);
            setHeaders(res.data.headers);
            setRowsMap(res.data.rows);
        }
        else
            toast_error("Cannot load similar properties");
    }

    const doAdvancedSearch = async (obj, pageNumber: number) => {
        http.cancelRequestsByUrlPattern("/properties/filter");
        let res: RestResponse = await http.post("/properties/filter", obj);
        if (res.success) {
            setTotal(res.data.total);
            setHeaders(res.data.headers);
            setRowsAtIndex(pageNumber, res.data.rows);
        }
    }

    const searchByText = async (key: string, value: string) => {
        http.cancelRequestsByUrlPattern("/properties/search?");
        let url = `/properties/search?${key}=${value}`;
        let res: RestResponse = await http.get(url);
        if (res.success) {
            setTotal(res.data.total);
            setHeaders(res.data.headers);
            setRowsAtIndex(pageNumber, res.data.rows);
        }
    }

    const setFilters = async (newFilters) => {
        _setFilters(newFilters);
        localStorage.setItem("propertySearchFilters", JSON.stringify(newFilters));
        let newCount = Object.keys(newFilters).reduce((acc, key) => {
            if (newFilters[key] !== undefined && newFilters[key] !== null && newFilters[key] !== "" &&
                !(Array.isArray(newFilters[key]) && newFilters[key].length === 0) &&
                !(typeof newFilters[key] === 'object' && Object.keys(newFilters[key]).length === 0)) {
                acc++;
            }
            return acc;
        }, 0);
        setFilterCount(newCount);
    }

    const handleFilterSubmit = (formData, doSearch: boolean = true) => {
        setFilters(formData);
        navigate('/property-search');
        if(doSearch) {
            handleParams();
        }
        setOpen(false);
    };

    const handleExport = async () => {
        if (hasValidFilters()) return exportFilteredProperties();
        if (!!key && !!value) return exportSearchResults();
    }

    const exportFilteredProperties = async () => {
        await http.post("/properties/exportFiltered", filters, {}, true);
    }

    const exportSearchResults = async () => {
        await http.post(`/properties/exportSearched?${key}=${value}`, {}, {}, true);
    }

    const showModal = () => {
        setOpen(true);
    };

    const handleReset = () => {
        http.cancelRequestsByUrlPattern("/properties")
        setRowsMap({1: []});
        handleFilterSubmit(emptyPropertySearchDto(), false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handlePageChange: PaginationProps['onChange'] = (page) => {
        window.scrollTo(0, 0);
        setPageNumber(page);
        handleParams(page);
    }

    const handleClearCriteria = () => {
        handleReset();
        setSearchCriteria({ key: null, value: null });
    }

    const renderFilterTags = () => {
        return Object.entries(filters).map(([key, value]) => {
            if (value !== undefined && value !== null && value !== "" &&
                !(Array.isArray(value) && value.length === 0) &&
                !(typeof value === 'object' && Object.keys(value).length === 0)) {
                return (
                    <Tag key={key} closable onClose={() => handleRemoveFilter(key)} style={{ marginRight: '8px' }}>
                        <b>{camelCaseToNormalString(key)}:</b> {Array.isArray(value) ? value.join(', ') : value.toString()}
                    </Tag>
                );
            }
            return null;
        }).filter(Boolean);
    };

    const handleRemoveFilter = (key: string) => {
        const newFilters = { ...filters };
        delete newFilters[key];
        setFilters(newFilters);
        setRowsMap({1: []});
        setPageNumber(1);
        handleParams();
    };

    return (
        <Layout>
            <SideMenu recentlyViewed={recentlyViewed} />
            <Layout style={{ paddingLeft: 'max(2%, 20px' }}>

                {location.pathname === '/property-search' ? (
                    <>
                        <Header style={{ padding: 0, background: '#f5f5f5' }}>
                            <div className='filter-container'>
                                <div className='fixed-tags'>
                                    {rowsMap[pageNumber] && rowsMap[pageNumber].length > 0 && allowExport() && (
                                        <Tag onClick={handleExport} style={{ marginRight: '8px', cursor: 'pointer' }}>
                                            <DownloadOutlined /> Export results
                                        </Tag>
                                    )}
                                    <Tag color={filterCount > 0 ? 'blue' : 'default'} onClick={showModal} style={{ marginRight: '8px', cursor: 'pointer' }}>
                                        <FilterOutlined /> Filters ({filterCount})
                                    </Tag>
                                    <Modal
                                        open={open}
                                        title="Search Filters"
                                        onCancel={handleCancel}
                                        footer={[
                                            <Button key="reset" onClick={handleReset}>
                                                Reset
                                            </Button>,
                                            <Button key="submit" type="primary" form="propertySearchFiltersForm" htmlType="submit">
                                                Submit
                                            </Button>
                                        ]}
                                    >
                                        <PropertySearchFilters onSubmit={handleFilterSubmit} existingFilters={filters} />
                                    </Modal>
                                </div>
                                <div className='scrollable-tags-container'>
                                    <div className='scrollable-tags'>
                                        {!!key && !!value && (
                                            <Tag closable onClose={handleClearCriteria} style={{ marginRight: '8px' }}>
                                                <b>{key}:</b> '{value}'
                                            </Tag>
                                        )}
                                        {renderFilterTags()}
                                    </div>
                                </div>
                                <div>
                                    {!!key && !!value ? (
                                        <span className='sub-text'>Showing most relevant results</span>
                                    ) : !!simProp && !!propertyId ? (
                                        <span className='sub-text'>Showing results similar to Property {propertyId}</span>
                                    ) : (
                                        <>
                                            {!!recent && (
                                                <span className='sub-text'>Showing recently viewed</span>
                                            )}
                                            {allowPagination() && (
                                                <Pagination
                                                    style={{ marginTop: 20 }}
                                                    current={pageNumber}
                                                    simple
                                                    showSizeChanger={false}
                                                    onChange={handlePageChange}
                                                    pageSize={PAGE_SIZE}
                                                    total={total}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </Header>
                        {!allowPagination() ? (
                            <PropertyTextSearch />
                        ) : (
                            <>
                                {/* <PropertyList results={results} /> */}
                                {/* <PropertySummaryTable properties={results} loading={loading}/> */}
                                <GenericTable data={{headers: headers || [], rows: rowsMap[pageNumber] || []}} loading={loading}/>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                                    {!(!!key && !!value) && !(!!simProp && !!propertyId) && allowPagination() && (
                                        <Pagination
                                            current={pageNumber}
                                            simple
                                            showSizeChanger={false}
                                            onChange={handlePageChange}
                                            pageSize={PAGE_SIZE}
                                            total={total}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <Outlet />
                )}
            </Layout>
        </Layout>
    )
}
